<div fxLayout="column" style="margin-bottom: 30px;" fxLayoutAlign="center center">
    <div *ngIf="isLoading"  fxLayout="column" style="margin-top: 50px;">
        <app-spinner></app-spinner>
    </div>
    <div *ngIf="!isLoading" fxLayout="column">
        <div class="titulos-ind" fxLayout="row" fxLayoutAlign="space-between center" >
            <h3>Indicadores Económicos</h3>
            <h3>Apertura de Turno: S/ {{turno.monto_inicial_efectivo}}</h3>
        </div>
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-around center">
            <ng-container *ngFor="let indicador of listaIndicadoresEconomicos">
                <div>
                    <app-indicador-resumen 
                    [showDecimal]="true" 
                    [title]="indicador.title" 
                    [icon]="indicador.icon" 
                    [qyt]="indicador.qyt" 
                    [isGray]="indicador.isGray" >
                    </app-indicador-resumen>
                </div>
            </ng-container>       
        </div>
        <div style="margin-top: 20px;">
            <mat-divider></mat-divider>
        </div>
        <div class="titulos-ind">
            <h3>Indicadores Cuantificables</h3>
        </div>
        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-around center">
            <ng-container *ngFor="let indicador of listaIndicadoresCantidad">
                <app-indicador-resumen    [showDecimal]="false" 
                [title]="indicador.title" 
                [icon]="indicador.icon" 
                [qyt]="indicador.qyt" 
                [isGray]="indicador.isGray">
                </app-indicador-resumen>
            </ng-container>
        </div>
    </div>


</div>
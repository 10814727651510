import { Injectable } from '@angular/core';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { VentaCerradaDaService } from './venta-cerrada-da.service';
import { IRespuestaReseller } from 'src/app/shared/services/models/IRespuestaReseller';
import { Observable } from 'rxjs';
import { Ventas } from '../../registrar-pedido/models/Ventas';

@Injectable({
  providedIn: 'root'
})
export class VentaCerradaBlService {

  constructor(
    private registroService: RegistroService,
    private ventaCerradaServiceDA: VentaCerradaDaService
  ) { }

  // updateVentaCerrada(idVenta:string, respuesta:IRespuestaReseller, idTurno:string){
  //   const idEmpresa = this.registroService.empresa.id;
  //   return this.ventaCerradaServiceDA.updateVentaCerrada(idVenta,idEmpresa, respuesta, idTurno);
    
  //   //***///continuar con la actualizacion de venta
  // }

  existFechVenta(idTurno:string){
    const idEmpresa = this.registroService.empresa.id;
    this.ventaCerradaServiceDA.fetchVentas(idEmpresa, idTurno);
  }

  getVenta():Observable<Ventas[] | null | undefined>{
    return this.ventaCerradaServiceDA.ventasRep$;
  }
}

import { Injectable } from '@angular/core';
import { RegistroService } from './registro.service';
import { IEmpresa } from '../components/dialog/models/empresa.model';
import { Pedido } from '../components/registrar-pedido/models/Pedido';
import { IInvoice } from './models/IInvoice';
import { ComprobanteElectronico } from '../components/dialog/models/comprobante-electronico';
import { SendPersona } from './models/sendPersona';
import { IItems } from './models/IItems';
import { IProductoTicket } from '../components/registrar-pedido/models/IProductoTicket';
import { FunctionsService } from './functions.service';
import { Utils } from '../helpers/utils';
import { AspectosGenerales } from '../components/procesos/gestion-aspectos-generales/models/AspectosGenerales';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { IGetAPIRUC } from './models/IGetAPIRUC';
import { IInvoiceAnular } from './models/IInvoiceAnular';
import { IInvoiceAnularConsulta } from './models/IInvoiceAnularConsulta';

@Injectable({
  providedIn: 'root',
})
export class SendApiComprobanteElectronicoService {
  
  constructor(
    private registroService: RegistroService,
    private functionService: FunctionsService,
    private http: HttpClient
  ) {}

  emitirBoletaVenta(pedido: Pedido, personaSend: SendPersona | null, listaProductoPedido: IProductoTicket[],
     aspectosGenerales: AspectosGenerales, esCifrado:boolean, esBoleta:boolean, rucSend:IGetAPIRUC | null): Observable<any> {    
    const empresa = this.registroService.empresa as IEmpresa;
    if (empresa.comprobante_electronico != undefined) {
      let cuerpoBoleta = this.generarComprobanteVenta( pedido, empresa.comprobante_electronico, personaSend, listaProductoPedido, aspectosGenerales, esBoleta, rucSend);
      //emitir boleta de venta
      return this.enviarBoletaPSEReseller(cuerpoBoleta, empresa,esCifrado, esBoleta);
    } else return of();
  }

  anularComprobanteElectronico(pedido:Pedido, esCifrado:boolean, idTurno:string){
    const invoiceAnular = this.generarAnulacionComprobante(pedido);
    const empresa = this.registroService.empresa as IEmpresa;
    if (empresa.comprobante_electronico != undefined) {      
      //solicitar anulacion
      return this.enviarAnulacionPSEReseller(invoiceAnular, empresa, idTurno, pedido.id!, esCifrado);
    } else return of();
  }

  consultaAnularComprobanteElectronico(pedido:Pedido, esCifrado:boolean, idTurno:string){
    const invoiceAnular = this.generarConsultaAnulacionComprobante(pedido);
    const empresa = this.registroService.empresa as IEmpresa;
    if (empresa.comprobante_electronico != undefined) {      
      //solicitar anulacion
      return this.enviarConsultaAnulacionPSEReseller(invoiceAnular, empresa, idTurno, pedido.id!, esCifrado);
    } else return of();
  }

  private generarItems(listaProductoPedido: IProductoTicket[]) {
    let listaItems: IItems[] = [];
    let totalDescuentoAcumulado:number=0;

    listaProductoPedido.forEach((prod) => {
      let tieneDescuento:boolean=false;
      let totalDescuento:number=0;

      if(prod.descuento!=undefined){
        tieneDescuento=true;
        totalDescuento = Utils.redondeoSUNAT(Number(prod.descuento!.total_anterior_sin_igv)- Number(prod.descuento!.total_actual_sin_igv));
        totalDescuentoAcumulado = totalDescuento+totalDescuentoAcumulado;
      }

      let tieneIndicencia:boolean = false;      
      if(prod.incidencia!=undefined){
        tieneIndicencia=true;
      }

      if(tieneIndicencia){
        if(prod.cantidad_producto!= prod.incidencia!.cantidad_producto){
          const cantidadFinalProducto:number =  Number(prod.cantidad_producto - prod.incidencia!.cantidad_producto);
          const item: IItems = {
            unidad_de_medida: 'NIU', // NIU para producto, ZZ para servicio
            codigo: prod.codigo_producto,
            descripcion: prod.nombre_producto,
            cantidad: cantidadFinalProducto,
            valor_unitario: prod.valor_producto,
            precio_unitario: prod.precio_unitario,
            igv: prod.igv_valor_producto * cantidadFinalProducto,
            total: prod.precio_unitario * cantidadFinalProducto,
            subtotal: prod.valor_producto * cantidadFinalProducto,
            descuento:'',
            tipo_de_igv: 1,
            anticipo_regularizacion: false,
            anticipo_comprobante_numero: '',
            anticipo_comprobante_serie: '',
          };
          listaItems.push(item);
        }
      }else{
        const item: IItems = {
          unidad_de_medida: 'NIU', // NIU para producto, ZZ para servicio
          codigo: prod.codigo_producto,
          descripcion: tieneDescuento?prod.nombre_producto+' (-'+prod.descuento!.porcentaje_descuento+'% x'+prod.descuento!.cantidad_producto+')': prod.nombre_producto,
          cantidad: prod.cantidad_producto,
          valor_unitario: prod.valor_producto,
          precio_unitario: prod.precio_unitario,
          igv: tieneDescuento? prod.descuento!.igv_actual: prod.igv_valor_producto * prod.cantidad_producto,//cuando tiene descuento consideramos montos de los descuentos
          total: tieneDescuento?  prod.descuento!.total_actual_con_igv: prod.precio_unitario * prod.cantidad_producto,
          subtotal: tieneDescuento? (prod.descuento!.total_actual_con_igv - prod.descuento!.igv_actual) :  prod.valor_producto * prod.cantidad_producto,
          descuento: tieneDescuento? totalDescuento:'',
          tipo_de_igv: 1,
          anticipo_regularizacion: false,
          anticipo_comprobante_numero: '',
          anticipo_comprobante_serie: '',
        };
        listaItems.push(item);
      }
           
    });
    let valRetorno:any[]=[];
    valRetorno.push(listaItems);
    valRetorno.push(totalDescuentoAcumulado);
    return valRetorno;
  }

  private incluirServicio(pedido: Pedido, aspectosGenerales:AspectosGenerales) {
    const item: IItems = {
      unidad_de_medida: 'NIU', // NIU para producto, ZZ para servicio
      codigo: 'servi',
      descripcion: 'Servicio ' +'('+aspectosGenerales.tasa_servicio.toString()+'%)',
      cantidad: 1,
      valor_unitario: pedido.servicio_subtotal!,
      precio_unitario: pedido.servicio_igv!+pedido.servicio_subtotal!,
      igv: pedido.servicio_igv!,
      total:  pedido.servicio_igv!+pedido.servicio_subtotal!,
      subtotal: pedido.servicio_subtotal!,
      descuento: '', //**********,
      tipo_de_igv: 1,
      anticipo_regularizacion: false,
      anticipo_comprobante_numero: '',
      anticipo_comprobante_serie: '',
    };
    return item;
  }

  private generarComprobanteVenta(    
    pedido: Pedido,
    comprobanteElectronico: ComprobanteElectronico,
    personaSend: SendPersona | null,
    listaProductoPedido: IProductoTicket[],
    aspectosGenerales: AspectosGenerales,
    esBoleta:boolean,
    rucSend: IGetAPIRUC | null) {
    
    //1.generando detalles de boleta.
    let listaItems: IItems[] = this.generarItems(listaProductoPedido)[0];
    let totalDescuentoAcumulado:number= this.generarItems(listaProductoPedido)[1];
    let valorServicio = 0;
    if (pedido.servicio_total) {
      const itemServicio = this.incluirServicio(pedido, aspectosGenerales);
      valorServicio = itemServicio.subtotal;
      listaItems.push(itemServicio);
    }

    let tipoDocumentoElectronico:number= esBoleta?2:1
    let clienteTipoDocumento:string=esBoleta?personaSend!.tipoDocumento:'6'//6:factura, consultar para boleta de venta sin nombre
    let clienteNumeroDocumento:string=esBoleta?personaSend!.dni:rucSend!.ruc;
    let clienteDenominacion:string = esBoleta?(personaSend!.nombres +' '+personaSend!.apellidoPaterno +' ' +personaSend!.apellidoMaterno):rucSend!.razonSocial!;

    

    //2. generando cuerpo de boleta.
    let invoice: IInvoice = {
      operacion: 'generar_comprobante',
      tipo_de_comprobante: tipoDocumentoElectronico, //1:factura, 2:boleta de venta
      serie: esBoleta?comprobanteElectronico.serie_boleta_pse: comprobanteElectronico.serie_factura_pse,
      numero: 0, //se completa en la cloud function // recuperando el dato actualizado
      sunat_transaction: 1, //venta interna
      cliente_tipo_de_documento: clienteTipoDocumento /*
                            6 = RUC - REGISTRO ÚNICO DE CONTRIBUYENTE, 
                            1 = DNI - DOC. NACIONAL DE IDENTIDAD,
                            - = VARIOS - VENTAS MENORES A S/.700.00 Y OTROS, 
                            4 = CARNET DE EXTRANJERÍA, 
                            7 = PASAPORTE,  
                            A = CÉDULA DIPLOMÁTICA DE IDENTIDAD
                            B = DOC.IDENT.PAIS.RESIDENCIA-NO.D
                            0 = NO DOMICILIADO, SIN RUC (EXPORTACIÓN)
                            G = Salvoconducto*/,
      cliente_numero_de_documento: clienteNumeroDocumento, //**********revisar para cuando no se manda dni
      cliente_denominacion: clienteDenominacion,
      fecha_de_emision: '', // se completa la fecha en la cloud function  //  dd-mm-yyyy
      moneda: 1, //soles
      tipo_de_cambio: '',
      porcentaje_de_igv: aspectosGenerales.tasa_impuesto,
      descuento_global: '',
      total_descuento: totalDescuentoAcumulado,
      total_anticipo: '',
      total_gravada: pedido.sub_total + valorServicio,
      total_inafecta: '',
      total_exonerada: '',
      total_igv: pedido.igv,
      total_gratuita: '',
      total_otros_cargos: '',
      total: pedido.total,
      percepcion_tipo: '',
      percepcion_base_imponible: '',
      total_percepcion: '',
      detraccion: false,
      documento_que_se_modifica_tipo: '',
      documento_que_se_modifica_serie: '',
      documento_que_se_modifica_numero: '',
      tipo_de_nota_de_credito: '',
      tipo_de_nota_de_debito: '',
      enviar_automaticamente_a_la_sunat: true,
      enviar_automaticamente_al_cliente: false,
      condiciones_de_pago: '',
      tabla_personalizada_codigo: '',
      formato_de_pdf: '',
      items: listaItems,
    };
    //3. retornando cuerpo y detalle
    return invoice;
  }

  private generarAnulacionComprobante(pedido:Pedido){
    const invoiceAnular:IInvoiceAnular={
      operacion:'generar_anulacion',
      motivo:'ERROR DEL SISTEMA',
      codigo_unico:pedido.codigo_pedido,
      numero:pedido.comprobante_electronico!.invoice.numero,
      serie:pedido.comprobante_electronico!.invoice.serie,
      tipo_de_comprobante:pedido.comprobante_electronico!.invoice.tipo_de_comprobante
    }
    return invoiceAnular
  }

  private generarConsultaAnulacionComprobante(pedido:Pedido){
    const invoiceConsulta:IInvoiceAnularConsulta={
      operacion:'consultar_anulacion',      
      numero:pedido.comprobante_electronico!.invoice.numero,
      serie:pedido.comprobante_electronico!.invoice.serie,
      tipo_de_comprobante:pedido.comprobante_electronico!.invoice.tipo_de_comprobante
    }
    return invoiceConsulta;

  }

  private enviarBoletaPSEReseller(jsonComprobante: any, empresa: IEmpresa, esCifrado:boolean, esBoleta:boolean) {
    const token = empresa.comprobante_electronico!.token_pse;
    const url = empresa.comprobante_electronico!.ruta_pse;
    const idEmpresa = empresa.id!;
    //console.log(jsonComprobante);
    const result = this.functionService.PostComprobanteElectronico(
      jsonComprobante,
      url,
      token,
      idEmpresa,
      esCifrado,
      esBoleta
    );
    return result;
  }

  private enviarAnulacionPSEReseller(jsonComprobante: any, empresa: IEmpresa, idTurno:string, idPedido:string, esCifrado:boolean) {
    const token = empresa.comprobante_electronico!.token_pse;
    const url = empresa.comprobante_electronico!.ruta_pse;
    const idEmpresa = empresa.id!;
    //console.log(jsonComprobante);
    const result = this.functionService.PostAnularComprobanteElectronico(
      jsonComprobante,
      url,
      token,
      idEmpresa,
      idTurno,
      idPedido,
      esCifrado      
    );
    return result;
  }

  private enviarConsultaAnulacionPSEReseller(jsonComprobante: any, empresa: IEmpresa, idTurno:string, idPedido:string, esCifrado:boolean) {
    const token = empresa.comprobante_electronico!.token_pse;
    const url = empresa.comprobante_electronico!.ruta_pse;
    const idEmpresa = empresa.id!;
    //console.log(jsonComprobante);
    const result = this.functionService.PostConsultaAnularComprobanteElectronico(
      jsonComprobante,
      url,
      token,
      idEmpresa,
      idTurno,
      idPedido,
      esCifrado      
    );
    return result;
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Length_Database, Msjs, Msjs_Validations, PatternsRegex, TableTypes } from 'src/app/shared/cons/common';
import { CONFIGURACION } from 'src/app/shared/cons/config';
import { Icons } from 'src/app/shared/cons/icons';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { Utils } from 'src/app/shared/helpers/utils';
import { GetApiDNIRUCService } from 'src/app/shared/services/get-api-dniruc.service';
import { IGetAPIPersona } from 'src/app/shared/services/models/IGetAPIPersona';
import { SendPersona } from 'src/app/shared/services/models/sendPersona';
import { Pedido } from '../../registrar-pedido/models/Pedido';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SendApiComprobanteElectronicoService } from 'src/app/shared/services/send-api-comprobante-electronico.service';
import { IProductoTicket } from '../../registrar-pedido/models/IProductoTicket';
import { GestionAspectosBlService } from '../gestion-aspectos-generales/services/services.bl/gestion-aspectos.bl.service';
import { AspectosGenerales } from '../gestion-aspectos-generales/models/AspectosGenerales';
import { IRespuestaReseller } from 'src/app/shared/services/models/IRespuestaReseller';
import { UIService } from 'src/app/shared/services/ui.services';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { Fecha } from 'functions/src/class_interface';

@Component({
  selector: 'app-emision-boleta-venta',
  templateUrl: './emision-boleta-venta.component.html',
  styleUrls: ['./emision-boleta-venta.component.scss'],
})
@AutoUnsubscribe
export class EmisionBoletaVentaComponent implements OnInit {
  tipoTabla:string = TableTypes.EMITIR_BOLETA;
  readonly TableTypes: typeof TableTypes = TableTypes;
  readonly Length_Database: typeof Length_Database = Length_Database;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Icons: typeof Icons = Icons;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  lTipo_documento: any[] = CONFIGURACION.TIPO_DOCUMENTO;
  isLoading: boolean = false;
  dniResponse: IGetAPIPersona | null = null;
  personaSend: SendPersona = new SendPersona();
  getAPIPersonaSub!: Subscription;
  esBuscado: boolean = false;
  onlyNumbers = Utils.InputOnlyNumbers;
  pedido = new Pedido();
  listaProductoPedido!: IProductoTicket[];
  tasasSb!: Subscription;
  aspectosGenerales = new AspectosGenerales();
  listaMesasImprimir:string='';
  fechaHoy: Fecha = new Fecha();

  constructor(
    private getApiDNIRUCService: GetApiDNIRUCService,

    private sendApiComprobante: SendApiComprobanteElectronicoService,
    @Inject(MAT_DIALOG_DATA) public datoPasado: any,
    private gestionAspectosBL: GestionAspectosBlService,
    private uiService: UIService
  ) {}

  ngOnInit(): void {
    this.pedido = this.datoPasado.dato as Pedido;
    this.listaProductoPedido = this.datoPasado.listaProductoPedido as IProductoTicket[];
    this.listaMesasImprimir = Utils.generarMesasFila(this.pedido.mesas_completo);  

    this.gestionAspectosBL.existFetchTasas();
    this.tasasSb?.unsubscribe();
    this.tasasSb = this.gestionAspectosBL.getTasas().subscribe((tasas) => {
      if (tasas) {
        this.aspectosGenerales = tasas;
      }
    });
  }

  buscarPorDNI(f: NgForm) {
    if (f.valid) {
      this.isLoading = true;
      this.getAPIPersonaSub = this.getApiDNIRUCService
        .getDNI(f.value.dni)
        .subscribe((res) => {
          if (res.success && res.nombres != '') {
            this.esBuscado = true;
            this.dniResponse = res as IGetAPIPersona;
            this.isLoading = false;
          } else {
            this.esBuscado = true;
            this.dniResponse = null;
            this.isLoading = false;
          }
        });
    }
  }

  generarBoletaElectronica(){
    this.sendApiComprobante.emitirBoletaVenta(      
      this.pedido,
      this.personaSend,
      this.listaProductoPedido,
      this.aspectosGenerales,
      true,//parametro de cifrado
      true,//es boleta de venta
      null // cuando es factura
    )
    .subscribe((res) => {      
      if (res && res.status == 'ok') {
        this.uiService.loadingSend$.next(false);
        this.uiService.ShowSnackBar(
          SnackBarType.SUCCESS,
          Msjs.MSJ_GENERO_BOLETA,
          1500
        );       
        //console.log(res);
        if(res.fechaPrint){
          this.fechaHoy = res.fechaPrint as Fecha;
          //enviamos valores de retorno para impresion al componente padre Gestion-caja-cobro
          this.uiService.printComprobanteSendParams$.next({
              pedido:this.pedido,
              listaProductoPedido:this.listaProductoPedido,
              listaMesasImprimir:this.listaMesasImprimir,
              aspectosGenerales: JSON.parse(JSON.stringify(this.aspectosGenerales)),
              fechaHoy:this.fechaHoy,
              key:this.tipoTabla,
              tipoComprobante:1,//1:boleta solo interno app
              result:JSON.parse(JSON.stringify(res.data))as IRespuestaReseller,
              usuarioSend: this.personaSend
            });
          //comunica que se emitie comprobante  de venta   
          this.uiService.closeDialogComprobanteElect$.next(true);       
          
        }
        
      } else {
        if ((res.status = 'error')) {
          this.uiService.loadingSend$.next(false);
          this.uiService.ShowSnackBar(
            SnackBarType.ERROR,
            'Error: '+res.details.errors+' || Codigo:'+ res.details.codigo,
            3000
          );          
        }
      }
    });
  }

  emitirBoletaManualCNombre(f: NgForm) {
    if (f.valid) {
      switch(this.personaSend.tipoDocumento){
        case '1':{
          this.personaSend.tipoDocumento = '1'; // dni
          break;
        }
        case '2':{
          this.personaSend.tipoDocumento = '7'; // pasaporte
          break;
        }
        case '3':{
          this.personaSend.tipoDocumento = '4'; //carnet de extranjeria
          break;
        }
        default:{
          break
        }
      }

      this.uiService.loadingSend$.next(true);
      this.generarBoletaElectronica();      
    }
  }

  emitirBoletaAutomaticoConDatosAutomatico(){
    this.uiService.loadingSend$.next(true);
    this.personaSend.apellidoMaterno = this.dniResponse!.apellidoMaterno;
    this.personaSend.apellidoPaterno = this.dniResponse!.apellidoPaterno;
    this.personaSend.nombres = this.dniResponse!.nombres;
    this.personaSend.dni = this.dniResponse!.dni;
    this.personaSend.tipoDocumento =  '1' // tipo de documento para nubefact
    this.generarBoletaElectronica();

  }

  emitirBoletaSimple() {
    //no se emiten boletas sin nombre por un monto mayor a 700
    if(this.pedido.total>699){
      this.uiService.ShowSnackBar(
        SnackBarType.ERROR,
        Msjs.MSJ_BOLETA_MAYOR_700,
        1500
      );
      return;
    }
    this.uiService.loadingSend$.next(true);

    //cuandos e emite boleta sin nombre, segun nubefact
    this.personaSend.apellidoMaterno = '';
    this.personaSend.apellidoPaterno = 'Varios';
    this.personaSend.nombres = 'Cliente'
    this.personaSend.dni = '00000000';
    this.personaSend.tipoDocumento =  '1' // tipo de documento para nubefact
    this.generarBoletaElectronica();
  }

  onKeydown(event: KeyboardEvent, f: NgForm): void {
    if (event.key === 'Enter') {
      this.buscarPorDNI(f); // Llama al método que quieras ejecutar
    }
  }

  limpiarBusqueda(f: NgForm) {
    this.isLoading = false;
    this.esBuscado = false;
    this.dniResponse = null;
    f.reset();
  }
  LimpiarManual(f: NgForm) {
    this.isLoading = false;
    this.esBuscado = false;
    this.dniResponse = null;
    f.reset();
    this.personaSend = new SendPersona();
  }
}

import { Component, OnInit } from '@angular/core';
import { IIndicadorResumen } from '../indicador-resumen/models/IIndicadorResumen';
import { Icons } from '../../cons/icons';
import { VentaCerradaBlService } from '../procesos/services/venta-cerrada-bl.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { RegistroService } from '../../services/registro.service';
import { filter } from 'rxjs/operators';
import { GestionMontoBlService } from '../gestion-monto/services/gestion-monto.bl.service';
import { Ventas } from '../registrar-pedido/models/Ventas';
import { MediosPagoBlService } from '../procesos/gestion-medios-pago/services/medios-pago.bl.service';
import { MediosAceptados } from '../procesos/gestion-medios-pago/models/MediosAceptados';
import { ListaMediosPago } from '../../cons/common';
import { GestionAspectosBlService } from '../procesos/gestion-aspectos-generales/services/services.bl/gestion-aspectos.bl.service';
import { AspectosGenerales } from '../procesos/gestion-aspectos-generales/models/AspectosGenerales';
import { Turno } from '../gestion-monto/models/Turno';

@Component({
  selector: 'app-estado-caja',
  templateUrl: './estado-caja.component.html',
  styleUrls: ['./estado-caja.component.scss'],
})
@AutoUnsubscribe
export class EstadoCajaComponent implements OnInit {
  listaIndicadoresEconomicos: IIndicadorResumen[] = [];
  listaIndicadoresCantidad: IIndicadorResumen[] = [];
  TiposIndicadoresCantidad: any[] = [
    { title: 'Cantidad de ventas', icon: Icons.ALARM_SMART_WAKE },
    { title: 'Cantidad de Dsctos', icon: Icons.ARROW_SPLIT },
    { title: 'Cantidad de Incidencias', icon: Icons.ARROW_RIGHT },
  ];
  ventasSb!: Subscription;
  gestionMontoSb!: Subscription;
  mediosSb!: Subscription;
  mediosAceptados: MediosAceptados | null = null;
  readonly ListaMediosPago: typeof ListaMediosPago = ListaMediosPago;
  isLoading: boolean = false;
  configTasasSb!:Subscription;
  aspectosGenerales: AspectosGenerales = new AspectosGenerales();
  turno:Turno = new Turno();
  constructor(
    private ventaCerrada: VentaCerradaBlService,
    private registroService: RegistroService,
    private gestionMontoBlService: GestionMontoBlService,
    private mediosBLService: MediosPagoBlService,
    private gestionAspectosBL: GestionAspectosBlService,
  ) {}

  ngOnInit(): void {
    if (this.registroService.empresa) {
      this.isLoading = true;
      //recuperando turno
      this.gestionMontoBlService.existFetchTurno();
      this.gestionMontoSb?.unsubscribe();
      this.gestionMontoSb = this.gestionMontoBlService
        .getTurno()
        .subscribe((turn) => {
          if (turn) {
            this.turno = turn;
            //recuperando medios pago
            this.mediosBLService.existFecthMedios();
            this.mediosSb?.unsubscribe();
            this.mediosSb = this.mediosBLService.getTasas().subscribe((configAmb) => {
                if (configAmb) {
                  this.mediosAceptados = configAmb;

                  //recuperar aspectos generales
                  this.gestionAspectosBL.existFetchTasas();
                  this.configTasasSb?.unsubscribe();

                  this.configTasasSb= this.gestionAspectosBL.getTasas().pipe(
                    filter((aspectos)=>aspectos!==undefined)
                  ).subscribe(asp=>{
                    if(asp){
                        this.aspectosGenerales = asp;
                         //recuperando ventas
                        this.ventaCerrada.existFechVenta(turn.id!);
                        this.ventasSb?.unsubscribe();
                        this.ventasSb = this.ventaCerrada.getVenta().pipe(filter((ventas) => ventas !== undefined))
                          .subscribe((ventas) => {
                            this.isLoading = false;
                            if (ventas) {
                              this.calcularIndicadoresEconomicos(
                                this.mediosAceptados!,
                                ventas
                              );
                            }
                            // console.log(  this.mediosAceptados);
                            // console.log(ventas);
                          });
                    }
                  });               
                }
              });
          }
        });
    }
  }

  calcularIndicadoresEconomicos(
    mediosAceptados: MediosAceptados,
    listaVentas: Ventas[]
  ) {
    this.listaIndicadoresEconomicos = [];
    this.listaIndicadoresCantidad = [];
    let tVentas: IIndicadorResumen = {
      title: 'Total Venta',
      icon: Icons.SAVINGS,
      qyt: 0,
      activo: true,
      isGray:false
    };
    let tEfectivo: IIndicadorResumen = {
      title: 'Total Efectivo',
      icon: Icons.PAYMENTS,
      qyt: 0,
      activo: false,
      isGray:true
    };
    let tTarjeta: IIndicadorResumen = {
      title: 'Total Tarjeta',
      icon: Icons.CREDIT_CARD,
      qyt: 0,
      activo: false,
      isGray:true
    };
    let tTransferencia: IIndicadorResumen = {
      title: 'Total Transferencia',
      icon: Icons.LOCAL_ATM,
      qyt: 0,
      activo: false,
      isGray:true
    };
    let tYape: IIndicadorResumen = {
      title: 'Total Yape',
      icon: Icons.QR_CODE_2,
      qyt: 0,
      activo: false,
      isGray:true
    };
    let tPlin: IIndicadorResumen = {
      title: 'Total Plin',
      icon: Icons.QR_CODE_SCANNER,
      qyt: 0,
      activo: false,
      isGray:true
    };
    let tPropina: IIndicadorResumen = {
      title: 'Total Propina',
      icon: Icons.PAID,
      qyt: 0,
      activo: false,
      isGray:true
    };
    let tServicio: IIndicadorResumen = {
      title: 'Total Servicio',
      icon: Icons.SENTIMENT_NEUTRAL,
      qyt: 0,
      activo: false,
      isGray:true
    };

    //cuantificadores
    let cVentas: IIndicadorResumen = {
      title: 'Cantidad de Ventas',
      icon: Icons.FORMAT_LIST_NUMBERED,
      qyt: 0,
      activo: false,
      isGray:false
    };
    let cDscto: IIndicadorResumen = {
      title: 'Cantidad de Dsctos',
      icon: Icons.PERCENT,
      qyt: 0,
      activo: false,
      isGray:true
    };
    let cIncidencia: IIndicadorResumen = {
      title: 'Cantidad de Incidencias',
      icon: Icons.INFO,
      qyt: 0,
      activo: false,
      isGray:true
    };


    listaVentas.forEach((ventaX) => {
      ventaX.pedido!.lista_pago_total!.forEach((pago) => {        
        switch (pago.tipo_pago.codigo_medio) {
          //efectivo
          case ListaMediosPago.listaMediosPago[0].codigo_medio: {
            if(pago.exedente!=undefined){
              tEfectivo.qyt += Number(pago.monto!) -(Number(pago.exedente)*-1);
              tVentas.qyt += Number(pago.monto!) - (Number(pago.exedente)*-1);
            }else{
              tEfectivo.qyt += Number(pago.monto!);
              tVentas.qyt += Number(pago.monto!);
            }
            
            tEfectivo.activo = true;
            break;
          }
          //tarjeta
          case ListaMediosPago.listaMediosPago[1].codigo_medio: {
            tTarjeta.qyt += Number(pago.monto!);
            tVentas.qyt += Number(pago.monto!);
            tTarjeta.activo = true;
            break;
          }
          //transferencia
          case ListaMediosPago.listaMediosPago[2].codigo_medio: {
            tTransferencia.qyt += Number(pago.monto!);
            tVentas.qyt += Number(pago.monto!);
            tTransferencia.activo = true;
            break;
          }
          //yape
          case ListaMediosPago.listaMediosPago[3].codigo_medio: {
            tYape.qyt += Number(pago.monto!);
            tVentas.qyt += Number(pago.monto!);
            tYape.activo = true;
            break;
          }
          //plin
          case ListaMediosPago.listaMediosPago[4].codigo_medio: {
            tPlin.qyt += Number(pago.monto!);
            tVentas.qyt += Number(pago.monto!);
            tPlin.activo = true;
            break;
          }
          //propina
          case ListaMediosPago.listaMediosPago[5].codigo_medio: {
            tPropina.qyt += Number(pago.monto!);
            tPropina.activo = true;
            break;
          }
          default: {
          }
        }
      });
      if(ventaX.pedido!.servicio_total){
        tServicio.qyt+= ventaX.pedido!.servicio_total;
        tServicio.activo = true;
      }
      //indicadores cuantificados
      if(ventaX.pedido?.tiene_descuento){
        cDscto.qyt +=1
      }
      if(ventaX.pedido?.tiene_incidencia || ventaX.pedido?.es_pedido_incidencia){
        cIncidencia.qyt +=1
      }
      
    });
    this.mediosAceptados?.lista_medios_pago.forEach(medio=>{
      switch (medio.codigo_medio) {
        //efectivo
        case ListaMediosPago.listaMediosPago[0].codigo_medio: {          
          tEfectivo.activo = true;
          break;
        }
        //tarjeta
        case ListaMediosPago.listaMediosPago[1].codigo_medio: {          
          tTarjeta.activo = true;
          break;
        }
        //transferencia
        case ListaMediosPago.listaMediosPago[2].codigo_medio: {          
          tTransferencia.activo = true;
          break;
        }
        //yape
        case ListaMediosPago.listaMediosPago[3].codigo_medio: {          
          tYape.activo = true;
          break;
        }
        //plin
        case ListaMediosPago.listaMediosPago[4].codigo_medio: {          
          tPlin.activo = true;
          break;
        }
        //propina
        case ListaMediosPago.listaMediosPago[5].codigo_medio: {          
          tPropina.activo = true;
          break;
        }
        default: {
        }
      }
    });

    if (tVentas.activo) this.listaIndicadoresEconomicos.push(tVentas);
    if (tEfectivo.activo) this.listaIndicadoresEconomicos.push(tEfectivo);
    if (tTarjeta.activo) this.listaIndicadoresEconomicos.push(tTarjeta);
    if (tTransferencia.activo) this.listaIndicadoresEconomicos.push(tTransferencia);
    if (tYape.activo) this.listaIndicadoresEconomicos.push(tYape);
    if (tPlin.activo) this.listaIndicadoresEconomicos.push(tPlin);
    //if (tPropina.activo) this.listaIndicadoresEconomicos.push(tPropina);
    //if (tServicio.activo) this.listaIndicadoresEconomicos.push(tServicio);

    //indicadores cuantificados
    cVentas.qyt = listaVentas.length;
    this.listaIndicadoresCantidad.push(cVentas);
    this.listaIndicadoresCantidad.push(cDscto);
    this.listaIndicadoresCantidad.push(cIncidencia);


  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Length_Database, Msjs, Msjs_Validations, PatternsRegex, TableTypes } from 'src/app/shared/cons/common';
import { Icons } from 'src/app/shared/cons/icons';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { Utils } from 'src/app/shared/helpers/utils';
import { GetApiDNIRUCService } from 'src/app/shared/services/get-api-dniruc.service';
import { IGetAPIRUC } from 'src/app/shared/services/models/IGetAPIRUC';
import { Pedido } from '../../registrar-pedido/models/Pedido';
import { UIService } from 'src/app/shared/services/ui.services';
import { SendApiComprobanteElectronicoService } from 'src/app/shared/services/send-api-comprobante-electronico.service';
import { IProductoTicket } from '../../registrar-pedido/models/IProductoTicket';
import { GestionAspectosBlService } from '../gestion-aspectos-generales/services/services.bl/gestion-aspectos.bl.service';
import { AspectosGenerales } from '../gestion-aspectos-generales/models/AspectosGenerales';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { Fecha } from 'functions/src/class_interface';
import { IRespuestaReseller } from 'src/app/shared/services/models/IRespuestaReseller';
import { VentaCerradaBlService } from '../services/venta-cerrada-bl.service';
import { PedidoBlService } from '../../registrar-pedido/services/services.bl/pedido.bl.service';

@Component({
  selector: 'app-emision-factura',
  templateUrl: './emision-factura.component.html',
  styleUrls: ['./emision-factura.component.scss']
})

@AutoUnsubscribe

export class EmisionFacturaComponent implements OnInit {
  readonly Length_Database: typeof Length_Database = Length_Database;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Icons: typeof Icons = Icons;
  readonly Msjs_Validations:typeof Msjs_Validations = Msjs_Validations;
  isLoading:boolean=false;
  getAPIEmpresaSub!:Subscription;
  esBuscado:boolean = false;
  rucResponse: IGetAPIRUC | null=null;
  onlyNumbers = Utils.InputOnlyNumbers;
  pedido = new Pedido();
  listaProductoPedido!: IProductoTicket[];
  tasasSb!: Subscription;
  aspectosGenerales = new AspectosGenerales();
  fechaHoy: Fecha = new Fecha();
  listaMesasImprimir:string='';
  tipoTabla:string = TableTypes.EMITIR_FACTURA;
  //idVenta:string='';
  idTurno:string='';

  constructor(
    private getApiDNIRUCService: GetApiDNIRUCService,
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private uiService: UIService,
    private sendApiComprobante: SendApiComprobanteElectronicoService,
    private gestionAspectosBL: GestionAspectosBlService,
    //private ventaCerradaServiceBL: VentaCerradaBlService,
    private pedidoBLService:PedidoBlService
  ) { }

  ngOnInit(): void {
    this.pedido = this.datoPasado.dato as Pedido;
    //this.idVenta = this.datoPasado.idVenta;
    this.idTurno = this.datoPasado.idTurno;

    this.listaProductoPedido = this.datoPasado.listaProductoPedido as IProductoTicket[];
    this.listaMesasImprimir = Utils.generarMesasFila(this.pedido.mesas_completo); 
    
    this.gestionAspectosBL.existFetchTasas();
    this.tasasSb?.unsubscribe();
    this.tasasSb = this.gestionAspectosBL.getTasas().subscribe((tasas) => {
      if (tasas) {
        this.aspectosGenerales = tasas;
      }
    });
  }

  buscarPorRUC(f:NgForm){
    if(f.valid){
      this.isLoading = true;
      this.getAPIEmpresaSub = this.getApiDNIRUCService.getRUC(f.value.ruc).subscribe(res=>{
        if(res.ruc && res.razonSocial!="" ){
          this.esBuscado = true;
          this.rucResponse = res as IGetAPIRUC;
          this.isLoading = false;            
        }else{
          this.esBuscado = true;
          this.rucResponse = null;
          this.isLoading = false; 
        }
        
      });
    }  
  }

  generarFacturaElectronica(){
    this.sendApiComprobante.emitirBoletaVenta(      
      this.pedido,
      null,
      this.listaProductoPedido,
      this.aspectosGenerales,
      false,//parametro de cifrado
      false,//es boleta de venta
      this.rucResponse // cuando es factura
    )
    .subscribe((res) => {      
      if (res && res.status == 'ok') {
        this.uiService.loadingSend$.next(false);
        this.uiService.ShowSnackBar(
          SnackBarType.SUCCESS,
          Msjs.MSJ_GENERO_FACTURA,
          1500
        );
        //console.log(res);
        if(res.fechaPrint){
          this.fechaHoy = res.fechaPrint as Fecha;
          const respReseller = JSON.parse(JSON.stringify(res.data))as IRespuestaReseller;
          respReseller.entidad_recepcion = this.rucResponse!;


          //actualizamos la venta cerrada con la boleta emitida
          this.pedidoBLService.updateComprobantePedido(this.idTurno, this.pedido.id!, respReseller).then(result=>{
              if(result.tx){
                //enviamos valores de retorno para impresion al componente padre Gestion-caja-cobro
                this.uiService.printComprobanteSendParams$.next({
                  pedido:this.pedido,
                  listaProductoPedido:this.listaProductoPedido,
                  listaMesasImprimir:this.listaMesasImprimir,
                  aspectosGenerales: JSON.parse(JSON.stringify(this.aspectosGenerales)),
                  fechaHoy:this.fechaHoy,
                  key:this.tipoTabla,
                  tipoComprobante:2,//2:factura solo interno app
                  result:respReseller,
                  rucResponse: this.rucResponse
                });
                //comunica que se emitie comprobante  de venta   
                this.uiService.closeDialogComprobanteElect$.next(true); 
              }else{
                this.uiService.ShowSnackBar(
                  SnackBarType.ERROR,
                  'Error: '+res.details.errors+' || Codigo:'+ res.details.codigo,
                  3000
                );  
              }
          });
        }
      } else {
        if ((res.status = 'error')) {
          //console.log(res);
          this.uiService.loadingSend$.next(false);
          this.uiService.ShowSnackBar(
            SnackBarType.ERROR,
            'Error: '+res.details.errors+' || Codigo:'+ res.details.codigo,
            3000
          );
        }
      }
    });
  }


  emitirFacturaAutomatico(){
    this.uiService.loadingSend$.next(true);
    this.generarFacturaElectronica();
  }

  
  onKeydown(event: KeyboardEvent, f:NgForm): void {
    if (event.key === 'Enter') {
      this.buscarPorRUC(f); // Llama al método que quieras ejecutar
    }
  }

  limpiarBusqueda(f:NgForm){
    this.esBuscado = true;
    this.rucResponse = null;
    this.isLoading = false; 
    f.reset();
  }

}

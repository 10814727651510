import { Injectable } from '@angular/core';
import { PedidoDaService } from '../services.da/pedido.da.service';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { Pedido } from '../../models/Pedido';
import { ITablePedidosFiltro } from '../models/ITablePedidosFiltro';
import { EstadosPedidoType } from 'src/app/shared/types/estado-pedido-type';
import { Fecha } from '../../../gestion-monto/models/Fecha';
import { IMesaAmbiente } from '../../models/IMesaAmbiente';
import { IProductoTicket } from '../../models/IProductoTicket';
import { IAmbienteMesa } from '../../../procesos/gestion-ambientes-mesas/models/IAmbienteMesa';
import { EstadoPedido } from '../../models/EstadoPedido';
import { EstadosProducto } from '../../models/EstadoProducto';
import { TipoConsumibleType } from 'src/app/shared/types/tipo-consumible';
import { EstadosProductoType } from 'src/app/shared/types/estado-producto-type';
import { PagoCuenta } from '../../models/PagoCuenta';
import { IPagoCuenta } from '../../models/IPagoCuenta';
import { IPrePagoCuenta } from '../../models/IPrePagoCuenta';
import { ItemSidenav } from 'src/app/shared/models/item.model';
import { IRespuestaReseller } from 'src/app/shared/services/models/IRespuestaReseller';
import { IGetAPIRUC } from 'src/app/shared/services/models/IGetAPIRUC';
import { SendPersona } from 'src/app/shared/services/models/sendPersona';

@Injectable({
  providedIn: 'root'
})
export class PedidoBlService {

  constructor(
    private pedidoDAService:PedidoDaService,
    private registroService: RegistroService,
     
  ) { }

  insertPedido(pedido:Pedido, idTurno:string, listaProductoPedido:IProductoTicket[],
    historialVariacionPedido:EstadoPedido,
    historialVariacionMesa:IAmbienteMesa[],
    historialVariacionProducto:EstadosProducto[]

    ){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.pedidoDAService.insertPedido(pedido, idEmpresa, idTurno,correoUsuario, 
      listaProductoPedido, historialVariacionPedido, historialVariacionMesa, historialVariacionProducto
      );
  }

  insertPedidoTraspaso(
    pedidoTraspaso:Pedido, 
    idTurno:string, 
    listaProductoPedidoTraspaso:IProductoTicket[],
    historialVariacionPedidoTraspaso:EstadoPedido,
    historialVariacionMesaTraspaso:IAmbienteMesa[],
    historialVariacionProductoTraspaso:EstadosProducto[],
    //pedido original
    pedidoOriginal:Pedido, listaProductoPedidoOriginal:IProductoTicket[],
    historialVariacionPedidoOriginal:EstadoPedido,    
    historialVariacionProductoOriginal:EstadosProducto[],
    esDividir:boolean,
    esMesaExistente:boolean,
    pedidoOcupado: Pedido,

    ){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.pedidoDAService.insertPedidoTraspaso(pedidoTraspaso, idEmpresa, idTurno,correoUsuario, 
      listaProductoPedidoTraspaso, historialVariacionPedidoTraspaso, historialVariacionMesaTraspaso, historialVariacionProductoTraspaso,
      //
      pedidoOriginal,listaProductoPedidoOriginal, historialVariacionPedidoOriginal,
      historialVariacionProductoOriginal, esDividir,esMesaExistente, pedidoOcupado
      );
  }
  
  updatePedido (pedido:Pedido, idTurno:string, esEditarMesa:boolean, esEditarProducto:boolean, 
    historialVariacionMesa:IAmbienteMesa[], historialVariacionProducto:EstadosProducto[],
    historialVariacionPedido: EstadoPedido,listaProductoEliminado:IProductoTicket[], goOptionSidenav:ItemSidenav){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    const esAdministrador = this.registroService.esAdministrador;    
    return this.pedidoDAService.updatePedido(pedido, idEmpresa, idTurno, correoUsuario, esEditarMesa, 
      esEditarProducto,  esAdministrador, historialVariacionMesa, historialVariacionProducto, 
      historialVariacionPedido, listaProductoEliminado, goOptionSidenav);
  }

  updateVariacionMesa (pedido:Pedido, idTurno:string, esOk:boolean, fecha:Fecha, historialVariacionMesa:IAmbienteMesa[], historialVariacionPedido:EstadoPedido){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;    
    return this.pedidoDAService.updateVariacionMesa(pedido, idEmpresa, idTurno, correoUsuario, esOk, fecha, historialVariacionMesa, historialVariacionPedido);
  }

  updateVariacionProducto (pedido:Pedido, idTurno:string, esOk:boolean, 
    historialVariacionPedido:EstadoPedido, historialVariacionProducto:EstadosProducto[], productosVariados:IProductoTicket[]
    ){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;    
    return this.pedidoDAService.updateVariacionProducto(pedido, idEmpresa, idTurno, correoUsuario, esOk, 
      historialVariacionPedido, historialVariacionProducto, productosVariados);
  }

  updateAgregarProductoPedido(pedido:Pedido, idTurno:string, 
    historialVariacionPedido:EstadoPedido, historialVariacionProducto:EstadosProducto[], listaProductoPedido:IProductoTicket[]){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.pedidoDAService.updateAgregarProductoPedido(pedido, idEmpresa, idTurno, correoUsuario, 
        historialVariacionPedido, historialVariacionProducto, listaProductoPedido);
  }

  getFechaActual(){
    return this.pedidoDAService.getFechaActual();
  }

  getPedidos(idEmpresa:string, tablaFiltro:ITablePedidosFiltro, idTurno:string, esCerrar:boolean=false, 
      esPagar:boolean=false, esIncidencia:boolean=false, esIndividualizado:boolean=false,
      esVentaCerrada:boolean = false
    ){
    const correoUsuario = this.registroService.usuario.correo_electronico;
    const esAdministrador = this.registroService.esAdministrador;
    return this.pedidoDAService.getPedidos(idEmpresa, tablaFiltro, idTurno, esCerrar, esPagar, esIncidencia, 
      esIndividualizado, correoUsuario, esAdministrador, esVentaCerrada);
  }

  getProductosXPedido(idTurno:string, idPedido:string){
    const idEmpresa = this.registroService.empresa.id;
    return this.pedidoDAService.getProductosXPedido(idEmpresa,idTurno, idPedido);
  }

  getHistorial(idTurno:string, idPedido:string, hMesa:boolean, hProducto:boolean, hPedido:boolean){
    const idEmpresa = this.registroService.empresa.id;
    return this.pedidoDAService.getHistorial(idEmpresa, idTurno, idPedido, hMesa, hProducto, hPedido);
  }

  getPedidosProductosPaginado(idTurno: string, filtroPedido: ITablePedidosFiltro, tipoProducto:TipoConsumibleType){
    const idEmpresa = this.registroService.empresa.id;
    return this.pedidoDAService.getPedidosProductosPaginado(idEmpresa, idTurno, filtroPedido, tipoProducto);
  }

  //update barra
  updateEstadoProductoPedido(pedido:Pedido, idTurno:string, producto:IProductoTicket, historialProd:EstadosProducto, updEstadoPedido:boolean, updEstadoResumenProdPedido:boolean,historialPedido:EstadoPedido | null,tipoProducto: TipoConsumibleType ){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.pedidoDAService.updateEstadoProductoPedido(idEmpresa, correoUsuario, pedido, idTurno,  producto,  historialProd, updEstadoPedido, updEstadoResumenProdPedido,historialPedido, tipoProducto);
  }

  updateEstadoProductoPedidoProximoEstado(idTurno:string, pedido: Pedido,
    listaHistorialProd: EstadosProducto[], updEstadoPedido: boolean, historialPedido: EstadoPedido | null, tipoProducto: TipoConsumibleType){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.pedidoDAService.updateEstadoProductoPedidoProximoEstado(idEmpresa, idTurno, correoUsuario, pedido, listaHistorialProd,updEstadoPedido,historialPedido, tipoProducto);
  }

  anularPedido(pedido:Pedido, idTurno:string, historialPedido:EstadoPedido){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.pedidoDAService.anularPedido(idEmpresa, correoUsuario, pedido, idTurno, historialPedido);
  }

  updatePedidoEstadoCerradoOTerminado(idTurno:string,pedido:Pedido,historialPedido: EstadoPedido){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.pedidoDAService.updatePedidoEstadoCerradoOTerminado(idEmpresa,idTurno,correoUsuario,pedido,historialPedido);
  }

  updatePagarPedido(pedido:Pedido, idTurno:string, lista_pago_total:IPagoCuenta[], fechaHoy:any){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.pedidoDAService.updatePagarPedido(pedido, idEmpresa, idTurno, correoUsuario, lista_pago_total, fechaHoy)
  }

  updatePagarPedidoParcial(pedido:Pedido, idTurno:string, prePagoCuenta:IPrePagoCuenta){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.pedidoDAService.updatePagarPedidoParcial(pedido, idEmpresa, idTurno, correoUsuario, prePagoCuenta)
  }

  updateAsignarIncidenciaProducto(pedido:Pedido, idTurno:string, producto:IProductoTicket, historialProd:EstadosProducto){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.pedidoDAService.updateAsignarIncidenciaProducto(pedido, idEmpresa, idTurno, correoUsuario, producto, historialProd);
  }

  updateQuitarIncidenciaProducto(pedido:Pedido, idTurno:string, producto:IProductoTicket, tieneOtrosDsctos:boolean = false, historialProd:EstadosProducto){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.pedidoDAService.updateQuitarIncidenciaProducto(pedido, idEmpresa, idTurno, correoUsuario, producto, tieneOtrosDsctos, historialProd)
  }


  updateAsignarDescuentoProducto(pedido:Pedido, idTurno:string, producto:IProductoTicket){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.pedidoDAService.updateAsignarDescuentoProducto(pedido, idEmpresa, idTurno, correoUsuario, producto)
  }


  updateQuitarDescuentoProducto(pedido:Pedido, idTurno:string, producto:IProductoTicket, tieneOtrosDsctos:boolean = false){
    const idEmpresa = this.registroService.empresa.id;
    const correoUsuario = this.registroService.usuario.correo_electronico;
    return this.pedidoDAService.updateQuitarDescuentoProducto(pedido, idEmpresa, idTurno, correoUsuario, producto, tieneOtrosDsctos);
  }

  updateComprobantePedido(idTurno:string, idPedido:string, respReseller: IRespuestaReseller){
    const idEmpresa = this.registroService.empresa.id;
    return this.pedidoDAService.updateComprobantePedido(idEmpresa,idTurno,idPedido,respReseller);
  }
 
 
}

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CriteriosBusqueda, MsjEstado, Msjs, SistOperation, TableTypes, TamanioPagina } from '../../cons/common';
import { Icons } from '../../cons/icons';
import { EstadosPedidoType } from '../../types/estado-pedido-type';
import { Table } from '../table/table.model';
import { Pedido } from '../registrar-pedido/models/Pedido';
import { CBuscar } from '../buscar/CBuscar';
import { SpinnerPageService } from '../spinner-page/spinner-page.service';
import { RegistroService } from '../../services/registro.service';
import { GestionMontoBlService } from '../gestion-monto/services/gestion-monto.bl.service';
import { Subject, Subscription, of } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { Turno } from '../gestion-monto/models/Turno';
import { TableService } from '../table/table.service';
import { ITablePedidosFiltro } from '../registrar-pedido/services/models/ITablePedidosFiltro';
import { PedidoBlService } from '../registrar-pedido/services/services.bl/pedido.bl.service';
import { ITableEvento } from '../table/ITableEvento';
import { UIService } from '../../services/ui.services';
//import * as printJS from 'print-js';
import { Utils } from '../../helpers/utils';
import * as moment from "moment";
import { CONFIGURACION } from '../../cons/config';
import { FunctionsService } from '../../services/functions.service';
import { IProductoTicket } from '../registrar-pedido/models/IProductoTicket';
import { Fecha } from '../gestion-monto/models/Fecha';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { EstadoPedido } from '../registrar-pedido/models/EstadoPedido';
import { ProcesoType } from '../../types/proceso-type';
import { SnackBarType } from '../../types/snackbar-type';
import { GestionAspectosBlService } from '../procesos/gestion-aspectos-generales/services/services.bl/gestion-aspectos.bl.service';
import { AspectosGenerales } from '../procesos/gestion-aspectos-generales/models/AspectosGenerales';

@Component({
  selector: 'app-pedidos-cerrados',
  templateUrl: './pedidos-cerrados.component.html',
  styleUrls: ['./pedidos-cerrados.component.scss']
})

@AutoUnsubscribe

export class PedidosCerradosComponent implements OnInit, OnDestroy {
  tipoTabla:string = TableTypes.LISTAR_PEDIDOS_CERRADOS;
  readonly Icons: typeof Icons = Icons;
  readonly EstadosPedidoType: typeof EstadosPedidoType = EstadosPedidoType;
  dataTable:Table = new Table();
  pedidos:Pedido[]=[];
  tieneTurnoAperturado:boolean = false;
  isLoading:boolean=false;
  isExpanded:boolean = false;
  buscar:CBuscar = new CBuscar();
  listadoMesas:number[]= new Array();
  turnoSb!:Subscription;
  turno:Turno = new Turno();
  listaPedidoSb!: Subscription;
  btnVerSb!:Subscription;
  btnAbrirSb!:Subscription;
  btnImprimirSb!:Subscription;
 
  modalSb!:Subscription;
  private unsubscribe$ = new Subject<void>();
  fechaHoy: Fecha = new Fecha();
  historialVariacionPedido: EstadoPedido = new EstadoPedido();

  //impresion
  empresa!:any;
  logoEmpresa!:string;
  usuarioImprimir!:string;
  pedidoImprimir:Pedido = new Pedido();
  totalProductoImprimir:number=0;
  totalItemsImprimir:number=0;
  listaProductoPedido!:IProductoTicket[];
  listaMesasImprimir:string='';
  esAdministrador:boolean = false;
  tasasSb!: Subscription;
  aspectosGenerales = new AspectosGenerales();
  
  constructor(
    private spinnerPageService: SpinnerPageService,
    private registroService: RegistroService,
    private gestionMontoBLService: GestionMontoBlService,
    private tableService: TableService,
    private pedidoBlService: PedidoBlService,
    private uiService: UIService,
    private cdRef: ChangeDetectorRef,
    private functionService: FunctionsService,
    private pedidoBLService: PedidoBlService,
    private gestionAspectosBL: GestionAspectosBlService,
  ) {
    //definimos las propiedades de la tabla
    this.dataTable.modoXs = ['numeracion', 'codigo_pedido', 'estado_pedido_vigente','mesas_asignado','operaciones'];
    this.dataTable.modoM = ['numeracion', 'codigo_pedido', 'estado_pedido_vigente', 'total_productos_pedidos','fecha_registro','mesas_asignado','descuento','incidencia','pedido_incidencia' ,'operaciones'];
    this.dataTable.modoLg = ['numeracion', 'codigo_pedido', 'estado_pedido_vigente', 'total_productos_pedidos','mesas_asignado','total_string','fecha_registro', 'usuario_registro','descuento','incidencia', 'pedido_incidencia','operaciones'];
    this.dataTable.columnaEstado = 'estado_pedido_vigente';
    this.dataTable.columnaImagen = '';
    this.dataTable.columnaEstiloEstado = 'estado_pedido_vigente';
    this.dataTable.pageSizeOptions = TamanioPagina.LISTA_TAMANIOS
    this.dataTable.pageSize =  TamanioPagina.LISTA_TAMANIOS[0];// tamaño de pagina
    this.dataTable.currentPage = 1; //pagina inicial
    this.dataTable.nextPage = null;
    this.dataTable.previousPage = null;
    this.dataTable.columnaChip = undefined;
    this.dataTable.operaciones = [SistOperation.VER, SistOperation.ABRIR, SistOperation.IMPRIMIR];
    this.dataTable.filtro[0] = null; //filtro codigo
    this.dataTable.filtro[1] = null;
    this.dataTable.filtro[2] = null; //filtro usuario
    //this.dataTable.filtro[3] = null; //filtro mesas
    this.dataTable.filtro[4] = null; //filtro estadoPedidoVigenteFiltro
    this.dataTable.minPage = null;//cambia ordenamiendo descendente
    this.dataTable.maxPage = true;//cambia ordenamiendo ascendente
   }

  ngOnInit(): void {
 
    this.spinnerPageService.show();

     //solo para impresion
     if(this.registroService.empresa && this.registroService.usuario){
      this.empresa = this.registroService.empresa;
      if(this.empresa.logo){
        this.logoEmpresa = Utils.ObjecToListToArray(this.empresa?.logo_empresa)[0].path_image;
      }
      
      this.usuarioImprimir = this.registroService.usuario.correo_electronico;
    } 
    this.cargarBusqueda();
    if (this.registroService.empresa) {
      
      this.gestionAspectosBL.existFetchTasas();
      this.tasasSb?.unsubscribe();
      this.tasasSb = this.gestionAspectosBL.getTasas().subscribe((tasas) => {
        if (tasas) {
          this.aspectosGenerales = tasas;
        }
      });


      this.esAdministrador =  this.registroService.esAdministrador;
      this.dataTable.usuarioAdministrador = this.registroService.esAdministrador;
      this.isLoading = true;
      //consultando existencia de turno
      this.gestionMontoBLService.existFetchTurno();
      this.turnoSb = this.gestionMontoBLService.getTurno()
        .pipe(filter(turno => turno !== undefined))
        .subscribe(turno => {
          if (turno && turno.id) {
            this.isLoading = false;
            this.tieneTurnoAperturado = true;
            this.turno = turno;
            //generando la tabla de pedidos que pertenecen al turno
            this.generarTabla(turno.id);
          } else {
            this.isLoading = false;
            this.tieneTurnoAperturado = false;
          }
        });
    }

    //boton ver pedido
    this.btnVerSb=this.tableService.btnVer$.subscribe((callEvento:ITableEvento)=>{
      if (this.tipoTabla == callEvento.key) {
        const pedido= callEvento.data as Pedido;        
        if(this.turno.id && pedido.id){ 
          this.spinnerPageService.show();
          this.uiService.showDialoVerPedido({dato:pedido, turno:this.turno});
        }
      }
    });

    this.btnImprimirSb?.unsubscribe();
    this.btnImprimirSb = this.tableService.btnImprimir$
    .pipe(
      takeUntil(this.unsubscribe$),
      switchMap((callEvento: ITableEvento) => {
        if (this.tipoTabla === callEvento.key && this.turno.id && callEvento.data.id) {
          this.pedidoImprimir = callEvento.data as Pedido;
          this.spinnerPageService.show();
          return this.pedidoBlService.getProductosXPedido(this.turno.id, callEvento.data.id);
        } else {
          // Si las condiciones no se cumplen, devuelve un observable que no emite nada.
          return of(null);
        }
      })
    )
    .subscribe(async listaProds => {
      this.spinnerPageService.hide();
      if (listaProds) {
        this.listaProductoPedido = JSON.parse(JSON.stringify(listaProds));
        //quitando productos prepagados
        this.listaProductoPedido = JSON.parse(JSON.stringify(this.listaProductoPedido.filter(prod=>prod.es_pagado==undefined)));
        this.totalProductoImprimir = this.listaProductoPedido.reduce((acc, prod) => acc + prod.cantidad_producto, 0) || 0;
        this.totalItemsImprimir =  this.listaProductoPedido.length;
        this.listaMesasImprimir = Utils.generarMesasFila(this.pedidoImprimir.mesas_completo);         
        await this.cargarFechas();
        this.cdRef.detectChanges();                   
        this.uiService.printComanda$.next(this.tipoTabla);   
        
      }
    });

    this.btnAbrirSb = this.tableService.btnAbrir$.subscribe((callEvento: ITableEvento) => {
      if (this.tipoTabla == callEvento.key) {
        let pedido = callEvento.data as Pedido;
        if (this.turno.id && pedido.id) {
          const msjPrevio = pedido.codigo_pedido+' (mesa: '+pedido.mesas_completo+')';
          const msj = Msjs.MENSAJE_ABRIR_PEDIDO.replace('$', msjPrevio)          
          this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: msj, icon: Icons.QUESTION_MARK })
            .subscribe(async result => {
              if (result) {
                await this.cargarFechas();
                //para administrador, reabre el pedido, para colaborador solicita reapertura
                this.historialVariacionPedido = new EstadoPedido();
                this.historialVariacionPedido.es_vigente = true;
                this.historialVariacionPedido.estado_pedido = this.esAdministrador ? EstadosPedidoType.TERMINADO : EstadosPedidoType.PENDIENTE_REABRIR;
                this.historialVariacionPedido.fecha_key = { ...this.fechaHoy };
                this.historialVariacionPedido.proceso = ProcesoType.REABRIR_PEDIDO;
                this.historialVariacionPedido.auto_numerico = Utils.generaMesHoraAutonumerico(this.fechaHoy);
                this.historialVariacionPedido.usuario_creacion = this.registroService.usuario.correo_electronico;
                //cambiando de estado al pedido seleccionado
                pedido.estado_pedido_vigente = this.esAdministrador ? EstadosPedidoType.TERMINADO : EstadosPedidoType.PENDIENTE_REABRIR;
                this.spinnerPageService.show();
                this.pedidoBLService.updatePedidoEstadoCerradoOTerminado(this.turno.id!, pedido, this.historialVariacionPedido).then(tx => {
                  if (tx.tx) {
                    this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 5000);
                    //limpiando el pedido
                    this.historialVariacionPedido = new EstadoPedido();
                    this.spinnerPageService.hide();
                    //cambiando de pestaña a pedidos abiertos
                    this.uiService.showListarPedido.next();
                  }
                  else {
                    this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
                    this.spinnerPageService.hide();
                  }
                });
              }
              else {
                return;
              }
            });
        }
      }
    });

   
  }

  // printComanda(){
  //   var style = CONFIGURACION.COMANDA;

  //   const id='comandaPedCerrado';
  //   printJS({ printable: id, type: 'html', style:style, css:[CONFIGURACION.COMANDA_FUENTE] });
  // }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async cargarFechas() {
    const fechaServer = moment(await this.functionService.GetDateServer()).local().format();
    const fechaServerProcesado = Utils.separarFechasHoras(fechaServer);
    this.fechaHoy.anio = fechaServerProcesado[0];//anio
    this.fechaHoy.mes = fechaServerProcesado[1];//mes
    this.fechaHoy.dia = fechaServerProcesado[2];//dia
    this.fechaHoy.hora = fechaServerProcesado[3];//hora
    this.fechaHoy.minuto = fechaServerProcesado[4];//minuto
    this.fechaHoy.segundo = fechaServerProcesado[5];//seg  
  }


  generarTabla(idTurno:string){
    this.listaPedidoSb?.unsubscribe();
    this.tableService.isLoadingDataTable$.next({ key: this.tipoTabla, data: true });//loading tabla
    const tablaFiltro: ITablePedidosFiltro={
      codigoFiltro: this.dataTable.filtro[0], //codigo
      estadoFiltro: this.dataTable.filtro[1], //estado
      usuarioFiltro: this.dataTable.filtro[2], //usuario
      mesas: this.dataTable.filtro[3], //mesas
      estadoPedidoVigenteFiltro: this.dataTable.filtro[4], //estadoPedidoVigenteFiltro
      minPage: this.dataTable.minPage,
      maxPage: this.dataTable.maxPage,

    }

    const esCerrar:boolean = true;

    this.listaPedidoSb = this.pedidoBlService.getPedidos(
      this.registroService.empresa.id,
      tablaFiltro,
      idTurno,
      esCerrar,
      false,// esPagar
      false, //esIncidencia
      false, // esIndividualizado
    ).subscribe((pedidos: Pedido[] |null)=>{
      if(pedidos!=null && pedidos.length>0 && pedidos[0].codigo_pedido!= '' ){
        this.dataTable.data = [...pedidos];
      }else{
        this.dataTable.data = [];
      }
      //genera filtro mesas
      this.listadoMesas = new Array();
      if(pedidos!=null){
        pedidos.forEach(pedido=>{
          pedido.mesas_completo.forEach(mesas=>{
            this.listadoMesas.push(Number(mesas));
          });
        });
      }


      this.listadoMesas.sort((a, b) => a - b); //ordenamos las mesas


      this.tableService.loadDataTable$.next(this.tipoTabla);//refrescamos el objeto tabla
      this.tableService.isLoadingDataTable$.next({ key: this.tipoTabla, data: false });//loading tabla

      this.spinnerPageService.hide();//cerrado del ngOnInit
    });

  }

  restablecerEstado(){
    this.dataTable.filtro[0] = null;
    this.dataTable.filtro[1] = null;
    this.dataTable.filtro[2] = null;
    this.dataTable.filtro[3] = null;
    this.dataTable.filtro[4] = null;    
   
    if(this.turno.id){
      this.generarTabla(this.turno.id);
    }
    this.isExpanded = false;

  }

  cargarBusqueda() {
    this.buscar.lista_criterio = [CriteriosBusqueda.codigo];

    if (this.registroService.usuario && this.registroService.esAdministrador) {
      this.buscar.lista_criterio.push(CriteriosBusqueda.usuario_creacion);
    }  
    
    this.buscar.key = this.tipoTabla;
    this.buscar.lista_estado = [MsjEstado.ABIERTO, MsjEstado.CERRADO];
  }

  panelExpandedChange(event: any) {
    this.isExpanded = event;
  }
  seleccionarMesa(mesa:number){
    this.dataTable.filtro[0] = null;
    this.dataTable.filtro[1] = null;
    this.dataTable.filtro[2] = null;
    this.dataTable.filtro[3] = mesa.toString();
    this.dataTable.filtro[4] = null;
   
    if(this.turno.id){
      this.generarTabla(this.turno.id);
    }    
  }

}

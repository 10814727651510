import { AuditoriaSet } from "src/app/shared/models/auditoria.models";
import { IPedido } from "./IPedido";
import { IProductoTicket } from "./IProductoTicket";
import { Fecha } from "../../gestion-monto/models/Fecha";
import { EstadosPedidoType } from "src/app/shared/types/estado-pedido-type";
import { EstadoPedido } from "./EstadoPedido";
import { IAmbienteMesa } from "../../procesos/gestion-ambientes-mesas/models/IAmbienteMesa";
import { EstadosProductoType } from "src/app/shared/types/estado-producto-type";
import { PagoCuenta } from "./PagoCuenta";
import { ProcesoType } from "src/app/shared/types/proceso-type";
import { IAmbienteMesaDerivado } from "../../procesos/gestion-ambientes-mesas/models/IAmbienteMesaDerivado";
import { Ventas } from "./Ventas";
import { IRespuestaReseller } from "src/app/shared/services/models/IRespuestaReseller";
import { EstadosComprobanteElectronicoType } from "src/app/shared/types/estado-comprobante-electronico-type";
import { IUsuarioIncidencia } from "./IUsuarioIncidencia";

export class Pedido implements IPedido, AuditoriaSet{
  codigo_pedido:string='';
  auto_numerico:number=0;    
  estado_pedido_vigente: EstadosPedidoType = EstadosPedidoType.PENDIENTE;//estado actual del pedido
  ambiente_mesa: IAmbienteMesa[]=[];
  total_productos_pedidos:number=0;
  lista_producto?: IProductoTicket[];
  mesas_completo:string[]=[];
  sub_total:number=0;
  igv:number=0;
  servicio_total?:number=0;
  servicio_igv?:number=0;
  servicio_subtotal?:number =0;
  total:number=0;
  es_borrado: boolean=false;
  es_vigente: boolean=false;
  usuario_creacion: any;
  usuario_creacion_busqueda:string='';
  usuario_modificacion?: any;
  fecha_creacion?: any;
  fecha_modificacion?: any;
  anotacion?:string;
  fecha_key:Fecha = new Fecha();
  estado_pedido_vigente_bebida?: EstadosProductoType;
  estado_pedido_vigente_comida?: EstadosProductoType;
  lista_pago_total?: PagoCuenta[];
  comprobante_electronico?:IRespuestaReseller;
  comprobante_electronico_requiere_sincronizacion?:boolean;// cuando se solicita anulacion de boleta pero NUBEFACT O SUNAT DEMORA EN el proceso
  fecha_key_pago_total?:Fecha;
  tiene_pago_total:boolean=false;
  tiene_pago_parcial:boolean = false;
  tiene_descuento?:boolean;
  tiene_incidencia?:boolean;
  proceso?: ProcesoType;
  codigo_pedido_origen?:string;
  es_ambiente_mesa_derivado?:boolean;
  comentario_ambiente_mesa_derivado?:string;
  es_pedido_incidencia?:boolean //indica si un pedido nace como incidencia
  usuario_incidencia?:IUsuarioIncidencia;

  //propiedades utilitarias
  pedido_incidencia?:string; //viene de es_pedido_incidencia
  tiempo_transcurrido_desde_creacion?:number;
  fecha_registro?:string;
  usuario_registro?:string;
  id?:string;  
  numeracion?:number;
  totalRegistros_query?:number
  totalRegistrosPendiente?:number;
  totalRegistrosEnProceso?:number;
  totalRegistrosTerminado?:number; 
  totalRegistrosAnulado?:number;
  totalRegistrosPendienteRevision?:number;
  totalRegistrosPendienteReabrir?:number;
  mesas_asignado?:string;
  total_string?:string;
  //para mostrar en barra o cocina
  last_doc:any;
  emision_comprobante?:EstadosComprobanteElectronicoType;
  descuento?:string;
  incidencia?:string;
  
}

/*cambiar para que no libere la mesa de inmediato cuando es editar, el adm tiene que aprobar*/
/*trabajar en el boton cancelar cuando se edita*/
/* trabajar en el editar de administrador*/
//revisar las mesas de R8IXFN

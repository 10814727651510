<div style="max-width: 300px;">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div mat-dialog-title class="dialog-title">Descuento Producto:&nbsp;{{producto.nombre_producto}}

        </div>
        <app-button type="icon" color="gray" [icon_fig]="Icons.CLOSE" (click)="onClose()"></app-button>
    </div>
    <mat-divider></mat-divider>
    <div style="margin-top: 20px;">
        <div fxLayout = "column" fxLayoutGap="5px" >
            <div *ngIf="totalConDescuento==null">
                <h2>Total:&nbsp;S/&nbsp;{{producto.precio_total | number:'1.2-2'}}</h2>
            </div>
            <div *ngIf="totalConDescuento!=null">
                <h2>Total:&nbsp;S/&nbsp;{{totalConDescuento | number:'1.2-2'}}</h2>
            </div>
            <div fxLayout="row" fxLayoutGap="10px" >
                <mat-chip-list *ngIf="producto.descuento!=undefined">
                    <mat-chip [matTooltip]="producto.descuento!.motivo" removable
                        (removed)="quitarDescuento()">
                        -{{producto.descuento!.porcentaje_descuento}}%&nbsp;(x{{producto.descuento!.cantidad_producto}})                  
                        <button matChipRemove>
                            <mat-icon>{{Icons.CANCEL}}</mat-icon>
                        </button>
                    </mat-chip>               
                </mat-chip-list>
                <div style="margin-top: -10px;" *ngIf="producto.descuento?.lista_caract_seleccionada_individualizada!=undefined" >
                    <app-button color="gray" type="icon" [icon_fig]="Icons.VISIBILITY"
                        matTooltip="Ver"                  
                        (click)="mostrarlistaDescuento()">
                    </app-button>          
                </div>  
            </div>
          
            <div *ngIf="producto.descuento?.comentario">
                <ul>
                    <li>
                        {{producto.descuento!.comentario}}
                    </li>
                </ul>
            </div>
            <mat-chip-list *ngIf="descuentoSel!=null && producto.descuento==undefined">
                <mat-chip [matTooltip]="descuentoSel.motivo">
                    -{{descuentoSel.item}}%&nbsp;(x {{cantidadSel}})
                </mat-chip>                
            </mat-chip-list>
            <div fxLayout="row" >        
                <div *ngIf="prodCompuestoSeleccionado" >
                    <span>Prod. seleccionado(s) :&nbsp;{{cantidadReturn}}</span>
                </div>
                <div style="margin-top: -10px; margin-left: 10px;" >
                    <app-button color="gray" type="icon" [icon_fig]="Icons.VISIBILITY"
                        matTooltip="Ver" 
                        *ngIf="prodCompuestoSeleccionado"
                        (click)="mostrarProductoCompuesto()">
                    </app-button>
                    <app-button color="gray" type="icon" [icon_fig]="Icons.CLEANING"
                        matTooltip="Limpiar" 
                        *ngIf="prodCompuestoSeleccionado"
                        (click)="limpiarProductoCompuestoSel()">
                    </app-button>
                </div>                
            </div>            
        </div>        
        <div style="margin-top: 10px;" >
            <mat-divider></mat-divider>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 10px;">
            <div fxLayout="row" style="font-size: 10pt;">
                <div>
                    <span>{{producto.cantidad_producto}}</span>
                    &nbsp;
                </div>
                <div>
                    <span>{{producto.nombre_producto}}</span>
                    &nbsp;
                </div>
                <div>
                    <span>(P.U. {{producto.precio_unitario | number:'1.2-2'}})</span>
                </div>                            
            </div>
            <div>
                <span>{{producto.precio_total | number:'1.2-2'}}</span>
            </div>
        </div>
        <div style="margin-top: 20px; margin-bottom: 10px;" 
            fxLayout="column" 
            fxLayoutAlign="space-around center">
            <mat-form-field *ngIf="producto.descuento==undefined && producto.cantidad_producto>1" style="width: 100%;">
                <mat-label>Cantidad de Producto</mat-label>
                <mat-select [(value)]="cantidadSel" (valueChange)="calcularDscto()">
                    <mat-option *ngFor="let item of listaCantidadProducto;let i=index"
                        [value]="item">{{item}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="producto.descuento==undefined" style="width: 100%;">
                <mat-label>Descuento %</mat-label>
                <mat-select [(value)]="descuentoSel" (valueChange)="calcularDscto()">
                    <mat-option *ngFor="let desc of descuento?.lista_descuento;let i=index"
                        [value]="desc">{{desc.item}}% - {{desc.motivo}}</mat-option>
                </mat-select>
            </mat-form-field>           
            <mat-form-field *ngIf="producto.descuento==undefined" style="width: 100%;">
                <mat-label>Comentario</mat-label>
                <textarea matInput ngModel [(ngModel)]="comentario"></textarea>
            </mat-form-field>
        </div>
        <mat-divider></mat-divider>       
        <div style="margin-top: 20px;"  fxLayout="row" fxLayoutAlign="space-around center" >
            <app-button *ngIf="producto.descuento==undefined" [width]="100" text="Asignar"
                [attr_disabled]="totalConDescuento==null"
                fxFlex="70%" fxFlex.xs="60%" 
                (click)="asignarDescuento()"></app-button>

            <app-button *ngIf="producto.descuento==undefined" [width]="100" text="Limpiar" color="gray" type="outline"
                (click)="limpiarDescuento()"></app-button>
        </div>
    </div>
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PedidoBlService } from '../../registrar-pedido/services/services.bl/pedido.bl.service';
import { UIService } from 'src/app/shared/services/ui.services';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { GestionAspectosBlService } from '../../procesos/gestion-aspectos-generales/services/services.bl/gestion-aspectos.bl.service';
import { GestionDescuentosBlService } from '../../procesos/gestion-descuentos-incidencias/services/gestion-descuentos.bl.service';
import { Pedido } from '../../registrar-pedido/models/Pedido';
import { Turno } from '../../gestion-monto/models/Turno';
import { IProductoTicket } from '../../registrar-pedido/models/IProductoTicket';
import { Icons } from 'src/app/shared/cons/icons';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { TipoPagoType } from 'src/app/shared/types/tipo-pago-type';
import { IDescuento } from '../../procesos/dialog/models/IDescuento';
import { Observable, Subscription, of } from 'rxjs';
import { Msjs } from 'src/app/shared/cons/common';
import { Utils } from 'src/app/shared/helpers/utils';
import { AspectosGenerales } from '../../procesos/gestion-aspectos-generales/models/AspectosGenerales';
import { catchError, filter, startWith, tap } from 'rxjs/operators';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { IDescuentoProducto } from '../../registrar-pedido/models/IDescuentoProducto';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { IItemDsctoIncidencia } from '../../procesos/dialog/models/IItemDsctoIncidencia';
import { TipoOperacionProductoCompuesto } from 'src/app/shared/types/tipo-operacion-prod-comp-type';

@Component({
  selector: 'app-gestion-descuento',
  templateUrl: './gestion-descuento.component.html',
  styleUrls: ['./gestion-descuento.component.scss']
})
@AutoUnsubscribe

export class GestionDescuentoComponent implements OnInit {
    readonly Icons: typeof Icons = Icons;
    pedido = new Pedido();
    turno = new Turno();
    producto!:IProductoTicket;
    tipo_pago!:TipoPagoType;
    listaProductoPedido: IProductoTicket[] = [];
    descuento!: IDescuento | null;
    descuentoSel:IItemDsctoIncidencia | null = null;
    totalConDescuento:number | null=null;
    modalSb!:Subscription
    configTasasSb!:Subscription;
    aspectosGeneralesSb!:Subscription;
    aspeGen:AspectosGenerales = new AspectosGenerales();
    existeConfigTasaServicio:boolean = false;
    comentario:string='';
    listaCantidadProducto:number[]=[];
    //sirve para descuento
    cantidadSel:number | null = null;
    cantidadReturn:number | null = null;
    cantidadRestanteReturn: number | null=null;
    //
    prodCompuestoSeleccionado:boolean=false;
    acumuladorDesc:number[]=[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef:MatDialogRef<GestionDescuentoComponent>,
    private pedidoBLService: PedidoBlService,
    private readonly spinnerPageService: SpinnerPageService,    
    private uiService:UIService,
    private gestionAspectosBL: GestionAspectosBlService,    
    private registroService: RegistroService,

  ) { }

  ngOnInit(): void {
    this.pedido = this.datoPasado.dato as Pedido;
    this.turno = this.datoPasado.turno as Turno;
    this.producto = JSON.parse(JSON.stringify(this.datoPasado.producto as IProductoTicket));
    this.tipo_pago = this.datoPasado.tipo_pago as TipoPagoType;
    this.listaProductoPedido = this.datoPasado.otros_productos as IProductoTicket[];
    if (this.turno.id && this.pedido.id) {
      this.descuento = this.datoPasado.descuento as IDescuento;     
      if(this.producto.descuento!=undefined){        
        this.descuentoSel = {
          item: this.producto.descuento!.porcentaje_descuento,
          motivo: this.producto.descuento!.motivo,
          position: 1,          
        }
      }
    }
    this.cargarTasas();
    this.generarCantidadProducto();
  }

  generarCantidadProducto(){  
    let inicial=1;
    let cantidad = this.producto.cantidad_producto;
    for(let i=1; i<=cantidad;i++){
      this.listaCantidadProducto.push(i);
    }

    if(cantidad==1){
      this.cantidadSel = 1
    }

  }

  onClose(){
    this.dialogRef.close({pedido:this.pedido, producto:this.producto});
  }

  cargarTasas(){
    this.gestionAspectosBL.existFetchTasas();
    this.configTasasSb?.unsubscribe();
    this.aspectosGeneralesSb = this.gestionAspectosBL.getTasas().subscribe(aspGen=>{
      if (aspGen) {
        this.aspeGen = aspGen;          
        this.existeConfigTasaServicio = true;                                     
      }
    })
  }

  quitarDescuento(){
    this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon: Icons.QUESTION_MARK })
    .subscribe(async result => {
      if(result){
        this.spinnerPageService.show();
                
        this.pedido.tiene_descuento = false;

        //gnerando objeto descuento para producto        
        let pedidoTmp:Pedido = JSON.parse(JSON.stringify(this.pedido));        
        this.producto.precio_total = this.producto.descuento!.total_anterior_con_igv;
      
        let productoTmp:IProductoTicket = JSON.parse(JSON.stringify(this.producto));
        
        //actualizando precio de producto
        this.listaProductoPedido.forEach(prod => {
          if (prod.es_pagado == undefined &&
              prod.id_producto == productoTmp.id_producto &&
              prod.codigo_ticket== productoTmp.codigo_ticket &&
              prod.descuento != undefined &&
              prod.precio_total == productoTmp.descuento!.total_actual_con_igv) {

                //recolocamos el precio anterior
                prod.precio_total = productoTmp.descuento!.total_anterior_con_igv;

          }
        });
         //recalculando el total
         pedidoTmp.total =0;
         this.listaProductoPedido.forEach(prod=>{
          if(prod.es_pagado==undefined){
            pedidoTmp.total += prod.precio_total;
          }         
        });       
        
        //recalculando el igv      
        const montos = Utils.calcularTotalTicket(this.existeConfigTasaServicio,this.aspeGen.tasa_impuesto, pedidoTmp.total, this.aspeGen.tasa_servicio);                
        pedidoTmp.igv = montos[0];
        pedidoTmp.sub_total = montos[1];
        if(this.aspeGen.incluir_servicio){
          pedidoTmp.servicio_total = montos[2];
          pedidoTmp.servicio_subtotal=montos[3];
          pedidoTmp.servicio_igv=montos[4];
        }        
        pedidoTmp.total = montos[5];

        const otrosDsctos = this.listaProductoPedido.filter(prod=>prod.id!==productoTmp.id && prod.tiene_descuento);

        const tieneOtrosDsctos = otrosDsctos!= undefined && otrosDsctos.length>0? true:false;
        this.pedido =  JSON.parse(JSON.stringify(pedidoTmp));
        this.producto =  JSON.parse(JSON.stringify(productoTmp));

        this.pedidoBLService.updateQuitarDescuentoProducto(pedidoTmp, this.turno.id!, productoTmp, tieneOtrosDsctos).then(tx=>{
          if(tx.tx){
            this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 5000);
            this.spinnerPageService.hide();
            delete this.producto.descuento;
            delete this.producto.tiene_descuento;
            this.totalConDescuento = null;
            this.descuentoSel=null;
            this.dialogRef.close({pedido:this.pedido});
          }
          else{
            this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
            this.spinnerPageService.hide();
          }
        });
      }
    });
  }

  cargarDialogCompuesto(cantidadSelX:number |null, esVer:boolean = false){
    const prodCompuesto = this.uiService.showDialogProductoCompuesto({
      producto: this.producto,
      tipo_pago: TipoOperacionProductoCompuesto.DESCUENTO,
      cantidad_sel: cantidadSelX,
      esVer:esVer
    });
    prodCompuesto.afterClosed().subscribe((result) => {
      if(!esVer){
        if(result.cantidad_restante=== 0){
          this.cantidadReturn = result.cantidad_acumulada;
          this.cantidadRestanteReturn = result.cantidad_restante;
          this.prodCompuestoSeleccionado= true;
          this.producto = JSON.parse(JSON.stringify(result.producto));
          //marcando todo el grupo de caracteristicas seleccionado
          const gruposProducto = this.producto.lista_caract_seleccionada_individualizada?.filter(caract=>caract.es_seleccionado);
          this.producto.lista_caract_seleccionada_individualizada?.forEach(caract=>{
            gruposProducto!.forEach(grupo=>{
              if(caract.grupo_producto===grupo.grupo_producto){
                caract.es_seleccionado = true;
              }
            });
          });          
        }else{
          this.uiService.ShowSnackBar(
            SnackBarType.ERROR,
            Msjs.MSJ_PRODUCTO_DIFERENTE,
            3000
          );
          this.limpiarProductoCompuestoSel();
        }   
      }            
    });
  }

  mostrarProductoCompuesto(){
    this.cargarDialogCompuesto(this.cantidadRestanteReturn, true);  
  }

  limpiarProductoCompuestoSel(){
    this.producto.lista_caract_seleccionada_individualizada?.forEach(caract=>{
      if(caract.es_seleccionado){
        delete caract.es_seleccionado;
      }
    });
    
    this.cantidadSel= null;
    this.descuentoSel = null;
    this.prodCompuestoSeleccionado = false;
    this.totalConDescuento = null;
    this.cantidadReturn = null;
  }

  calcularDscto(){
    if(this.cantidadSel==null){
      return;
    }else{
      if(this.cantidadSel !== this.cantidadReturn){
        //se selecciona el maximo de productos, no es necesario mostrar dialog
        if(this.cantidadSel === this.producto.cantidad_producto){
          this.producto.lista_caract_seleccionada_individualizada?.forEach(caract=>{
           caract.es_seleccionado = true;
          });
        }
        else{
          //se muestra el dialog
          if(this.producto.lista_caract_seleccionada_individualizada){
            this.cargarDialogCompuesto(this.cantidadSel);
          }              
        }        
      }
    }

    if(this.descuentoSel==null){
      return;
    }

    let count=1;
    this.acumuladorDesc=[];
    
    this.acumuladorDesc = Utils.calcularDescuento(Number(this.descuentoSel!.item), this.producto.precio_unitario,this.aspeGen.tasa_impuesto, this.cantidadSel, this.producto.cantidad_producto)
    this.totalConDescuento = this.acumuladorDesc[1];    
  }

  limpiarDescuento(){
    this.limpiarProductoCompuestoSel();
  }

  mostrarlistaDescuento(){
    const prodFake = JSON.parse(JSON.stringify(this.producto)) as IProductoTicket;
    prodFake.lista_caract_seleccionada_individualizada = this.producto.descuento!.lista_caract_seleccionada_individualizada;
    const prodCompuesto = this.uiService.showDialogProductoCompuesto({
      producto: prodFake,
      tipo_pago: TipoOperacionProductoCompuesto.DESCUENTO,
      cantidad_sel: this.producto.descuento!.cantidad_producto,
      esVer:true
    });
  }

  asignarDescuento(){
    if(this.totalConDescuento===null){
      this.uiService.ShowSnackBar(
        SnackBarType.ERROR,
        Msjs.MSJ_DSCTO_ERROR,
        3000
      );
      return;
    }
    this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon: Icons.QUESTION_MARK })
    .subscribe(async result => {
      if(result){
        this.spinnerPageService.show();
        //await this.cargarFechas();        
        this.pedido.tiene_descuento = true;
        
        //generando objeto descuento para producto
        const descuentoProd: IDescuentoProducto={
          porcentaje_descuento: Number(this.descuentoSel!.item),
          motivo: this.descuentoSel!.motivo,
          cantidad_producto: Number(this.cantidadSel),          
          total_actual_con_igv: this.totalConDescuento!,
          total_actual_sin_igv: this.acumuladorDesc[2],
          total_anterior_con_igv: this.producto.precio_total,
          total_anterior_sin_igv: this.producto.precio_total - (this.producto.igv_valor_producto * this.producto.cantidad_producto),
          igv_anterior: this.producto.igv_valor_producto * this.producto.cantidad_producto,
          igv_actual: this.acumuladorDesc[0],//igv acumulado del descuento
          usuario_creacion: this.registroService.usuario.correo_electronico,
          ...(this.producto.lista_caract_seleccionada_individualizada!=undefined && 
              {lista_caract_seleccionada_individualizada: JSON.parse(JSON.stringify(this.producto.lista_caract_seleccionada_individualizada!.filter(
              caract=> caract.es_seleccionado==true)))}),
          ...(this.comentario!='' && {comentario: this.comentario})
        }

        let productoTmp:IProductoTicket = JSON.parse(JSON.stringify(this.producto));
        productoTmp.descuento = descuentoProd;        
        productoTmp.tiene_descuento = true;
        productoTmp.precio_total = this.totalConDescuento!;
        productoTmp.igv_valor_producto = this.acumuladorDesc[0];

        let pedidoTmp:Pedido = JSON.parse(JSON.stringify(this.pedido));
        
        //actualizando precio de producto        
        this.listaProductoPedido.forEach(prod => {
          if (prod.es_pagado == undefined &&
              prod.id_producto == productoTmp.id_producto &&
              prod.codigo_ticket== productoTmp.codigo_ticket &&
              prod.descuento== undefined &&
              prod.precio_total == productoTmp.descuento!.total_anterior_con_igv) 
              {
              //recolocamos el precio anterior
              prod.precio_total = productoTmp.precio_total;
              }
        });       
        
        //recalculando el total
        pedidoTmp.total =0;
        this.listaProductoPedido.forEach(prod=>{
           if(prod.es_pagado==undefined)
            {
             pedidoTmp.total += prod.precio_total;
           }         
         }); 
        
         //recalculando el igv
        const montos = Utils.calcularTotalTicket(this.existeConfigTasaServicio,this.aspeGen.tasa_impuesto, pedidoTmp.total, this.aspeGen.tasa_servicio);        
        pedidoTmp.igv = montos[0];        
        pedidoTmp.sub_total = montos[1];
        if(this.aspeGen.incluir_servicio){
          pedidoTmp.servicio_total = montos[2];
          pedidoTmp.servicio_subtotal=montos[3];
          pedidoTmp.servicio_igv=montos[4];
        }        
        pedidoTmp.total = montos[5];

        this.producto =  JSON.parse(JSON.stringify(productoTmp));
        this.pedido =  JSON.parse(JSON.stringify(pedidoTmp));
        this.pedidoBLService.updateAsignarDescuentoProducto(pedidoTmp, this.turno.id!, productoTmp).then(tx=>{
          if(tx.tx){
            this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 5000);
            this.spinnerPageService.hide();
            this.dialogRef.close({pedido:this.pedido});       
          }
          else{
            this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
            this.spinnerPageService.hide();
          }
        });
      }
    });
  }


}

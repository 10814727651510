import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { TransaccionModel } from 'src/app/shared/services/models/trasaccion.model';
import { Ventas } from '../../registrar-pedido/models/Ventas';
import { Auditoria, colections, documentReporteVentas } from 'src/app/shared/cons/db.colections';
import { map } from 'rxjs/operators';
import { Utils } from 'src/app/shared/helpers/utils';


@Injectable({
  providedIn: 'root'
})
export class VentaCerradaDaService {
  transaccion: TransaccionModel = new TransaccionModel();

  private ventasRepCache: BehaviorSubject<Ventas[] | undefined | null> = new BehaviorSubject<Ventas[] | undefined |null>(undefined);
  public ventasRep$: Observable<Ventas[] | undefined |null> = this.ventasRepCache.asObservable();
  private fetchSb!: Subscription;


  constructor(private db: AngularFirestore) { }

  fetchVentas(idEmpresa:string, idTurno:string): void {
    //si ya existe subscripcion emite el ultimo valor
    if(this.fetchSb && !this.fetchSb.closed){
      //this.ambienteMesasCache.next(this.ambienteMesasCache.value);
      return;
    }

    //si no existe subscripcion, carga una nueva subscripcion
    this.fetchSb = this.getVentas(idEmpresa, idTurno).subscribe(ventas => {      
      this.ventasRepCache.next(ventas);
    });
  }

  stopFetchingVentas(): void {
    if (this.fetchSb) {
      this.fetchSb.unsubscribe();
    }
    this.ventasRepCache.next(undefined);
  }

  getVentas(idEmpresa: string, idTurno:string) {
    return this.db.collection(colections.EMPRESA, ref =>
      ref.where(Auditoria.es_borrado, '==', false))
      .doc(idEmpresa).collection(colections.REPORTE_VENTAS, ref1 =>
      ref1.where(Auditoria.es_vigente, '==', true)
      .where(documentReporteVentas.ID_TURNO,'==',idTurno))
      .snapshotChanges()
      .pipe(
        map(listaVentas => {
          if (listaVentas.length > 0) {
            // Reinicia el array dentro del pipe para asegurarte de que esté vacío en cada actualización
            let docsListaVentas: Ventas[] = [];
            listaVentas.forEach(venta => {
              const vta = Utils.convertDate( venta.payload.doc.data()) as Ventas;
              vta.id = venta.payload.doc.id;
              docsListaVentas.push(vta);
            });
            return docsListaVentas;
          } else {
            return null;
          }
        })
      );
  }

}

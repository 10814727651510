import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PedidoBlService } from '../../registrar-pedido/services/services.bl/pedido.bl.service';
import { SpinnerPageService } from '../../spinner-page/spinner-page.service';
import { UIService } from 'src/app/shared/services/ui.services';
import { Pedido } from '../../registrar-pedido/models/Pedido';
import { Turno } from '../../gestion-monto/models/Turno';
import { Observable, Subscription, combineLatest, forkJoin, of } from 'rxjs';
import { Icons } from 'src/app/shared/cons/icons';
import { EstadosProductoType } from 'src/app/shared/types/estado-producto-type';
import { Historial } from '../../historial/models/Historial';
import { IProductoTicket } from '../../registrar-pedido/models/IProductoTicket';
import { ListaMediosPago, Msjs, Msjs_Validations, PatternsRegex, TableTypes } from 'src/app/shared/cons/common';
import { KeyboardService } from 'src/app/shared/services/ui.teclado.service';
import { MediosPagoBlService } from '../../procesos/gestion-medios-pago/services/medios-pago.bl.service';
import { MediosAceptados } from '../../procesos/gestion-medios-pago/models/MediosAceptados';
import { MediosPago } from '../../procesos/gestion-medios-pago/models/MediosPago';
import { GestionAspectosBlService } from '../../procesos/gestion-aspectos-generales/services/services.bl/gestion-aspectos.bl.service';
import { AspectosGenerales } from '../../procesos/gestion-aspectos-generales/models/AspectosGenerales';
import { catchError, filter, finalize, map, startWith, tap } from 'rxjs/operators';
import { SnackBarType } from 'src/app/shared/types/snackbar-type';
import { PagoCuenta } from '../../registrar-pedido/models/PagoCuenta';
import { EstadosPedidoType } from 'src/app/shared/types/estado-pedido-type';
import { ITableEvento } from '../../table/ITableEvento';
import { AutoUnsubscribe } from 'src/app/shared/helpers/decorators/AutoUnsubscribe';
import { FunctionsService } from 'src/app/shared/services/functions.service';
import * as moment from "moment";
import { Utils } from 'src/app/shared/helpers/utils';
import { Fecha } from '../../gestion-monto/models/Fecha';
import { RegistroService } from 'src/app/shared/services/registro.service';
import { IPagoCuenta } from '../../registrar-pedido/models/IPagoCuenta';
import { IPrePagoCuenta } from '../../registrar-pedido/models/IPrePagoCuenta';
import { TipoPagoType } from 'src/app/shared/types/tipo-pago-type';
import { IDescuento } from '../../procesos/dialog/models/IDescuento';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'app-caja-cobro',
  templateUrl: './caja-cobro.component.html',
  styleUrls: ['./caja-cobro.component.scss'],

})

@AutoUnsubscribe

export class CajaCobroComponent implements OnInit {  
  readonly Icons: typeof Icons = Icons;
  pedido = new Pedido();

  turno = new Turno();
  //producto!:IProductoTicket;  
  tipo_pago!:TipoPagoType;
  listaProductoSb!:Subscription;
  totalProductos:number =0;
  listaProductoPedido: IProductoTicket[] = [];
  readonly EstadosProductoType:typeof EstadosProductoType = EstadosProductoType;
  readonly ListaMediosPago:typeof ListaMediosPago = ListaMediosPago;
  readonly EstadosPedidoType: typeof EstadosPedidoType = EstadosPedidoType;
  readonly TipoPagoType:typeof TipoPagoType = TipoPagoType;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  listaHMesa: Historial[]=[];
  listaHProducto:Historial[]=[];
  listaHPedido: Historial[]=[];
  startY: number = 0;
  endY: number = 0;
  isLoading:boolean = false;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  mediosAceptados:MediosAceptados | null =null;
  idMedios:string='';
  monto:string='';
  comentario:string='';
  keyboardNumSb!:Subscription;
  keyboardCharSb!:Subscription;
  reloadMediosSb!: Subscription;
  configSb!: Subscription;
  existeConfig:boolean = false;
  cEfectivo!:MediosPago | undefined;
  cTarjeta!:MediosPago | undefined;
  cTransferencia!:MediosPago | undefined;
  cYape!:MediosPago | undefined;
  cPlin!:MediosPago | undefined;
  aspectosGenerales$!: Observable<AspectosGenerales>; 
  idAspectos:string='';
  existeConfigTasaServicio:boolean = false;
  lista_pago_cuenta:PagoCuenta[]=[];
  lista_pago_cuenta_send:IPagoCuenta[]=[];
  modalSb!:Subscription;
  fechaHoy: Fecha = new Fecha();

  efectivoPago:PagoCuenta = new PagoCuenta();
  readonly TableTypes:typeof TableTypes=TableTypes;
  tarjetaPago:PagoCuenta = new PagoCuenta();
  transferenciaPago:PagoCuenta = new PagoCuenta();
  yapePago:PagoCuenta = new PagoCuenta();
  plinPago:PagoCuenta = new PagoCuenta();
  propinaPago:PagoCuenta = new PagoCuenta();
  subTotal:number = 0;
  restantePago:number =0;
  tieneExcedente:boolean = false;
  tienePropina:boolean = false;
  tasaImpuesto:number = 0;
  siglaImpuesto:string='';
  tasaServicio:number=0;
  incluirServicio:boolean = false;
  descuento!: IDescuento | null;
  descuentoSel:number | null = null;
  totalConDescuento:number | null = null;
  blinkState: string = 'inactive';
  configTasasSb!:Subscription;
  igvInformativo:number | null=null;
  subTotalInformativo:number | null =null;
  servicioInformativo:number | null = null; 
  totalInformativo:number | null=null;
  tieneEfectivo:boolean = false;
  private mediaSub!: Subscription;
  tamanioPantalla:string='';
  esMovil: boolean=false;
  bpointSub!:Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public datoPasado:any,
    private dialogRef:MatDialogRef<CajaCobroComponent>,
    private pedidoBLService: PedidoBlService,
    private readonly spinnerPageService: SpinnerPageService,    
    private uiService:UIService,
    private keyboardService: KeyboardService,
    private mediosBLService: MediosPagoBlService,
    private gestionAspectosBL: GestionAspectosBlService,
    private functionService: FunctionsService,
    private registroService: RegistroService,    
    private cdRef: ChangeDetectorRef,
    private mediaObserver: MediaObserver,
    
  ) {}
  

  ngOnInit(): void {

    setInterval(() => {
      this.blinkState = this.blinkState === 'active' ? 'inactive' : 'active';
    }, 800);  // Cambia el estado cada 500ms

    // Suscribirse a los cambios en el tamaño de pantalla
    this.mediaSub = this.mediaObserver.asObservable().subscribe((changes) => {
      this.tamanioPantalla = changes[0].mqAlias;     
    });

    this.pedido = this.datoPasado.dato as Pedido;
    this.turno = this.datoPasado.turno as Turno;
    this.tipo_pago = this.datoPasado.tipo_pago as TipoPagoType;
    if(this.tipo_pago== TipoPagoType.PARCIAL){
      const listaProdPass = JSON.parse(JSON.stringify(this.datoPasado.productos as IProductoTicket[])) as IProductoTicket[];
      this.listaProductoPedido = listaProdPass.filter(prod=>prod.es_seleccionado==true);
      //recalculando el total en el metodo cargarTasas(), porque depende de la tasa 
    }

    if(this.tipo_pago== TipoPagoType.TOTAL){
      this.cargarProductosXPedido(this.turno.id!, this.pedido.id!);
      this.listaProductoPedido = this.datoPasado.otros_productos as IProductoTicket[];
    }

    
    if (this.turno.id && this.pedido.id) {
      this.isLoading=true;
      this.cargarConfiguracion(); //medios de pago
    }
    this.keyboardNumSb = this.keyboardService.currentInput.subscribe((callEvento: ITableEvento) => {
      switch (callEvento.key) {
        case (TableTypes.CALC_EFECTIVO): {
          if (callEvento.data == 'C') {
            this.efectivoPago.monto = null;
          } else {
            if (this.efectivoPago.monto == null) {
              this.efectivoPago.monto = callEvento.data;
            }
            else {
              this.efectivoPago.monto += callEvento.data;
            }
          }
          break;
        }
        case (TableTypes.CALC_TARJETA): {
          if (callEvento.data == 'C') {
            this.tarjetaPago.monto = null;
          } else {
            if (this.tarjetaPago.monto == null) {
              this.tarjetaPago.monto = callEvento.data;
            }
            else {
              this.tarjetaPago.monto += callEvento.data;
            }
          }
          break;
        }
        case (TableTypes.CALC_YAPE): {
          if (callEvento.data == 'C') {
            this.yapePago.monto = null;
          } else {
            if (this.yapePago.monto == null) {
              this.yapePago.monto = callEvento.data;
            }
            else {
              this.yapePago.monto += callEvento.data;
            }
          }
          break;
        }
        case (TableTypes.CALC_PLIN): {
          if (callEvento.data == 'C') {
            this.plinPago.monto = null;
          } else {
            if (this.plinPago.monto == null) {
              this.plinPago.monto = callEvento.data;
            }
            else {
              this.plinPago.monto += callEvento.data;
            }
          }
          break;
        }
        case (TableTypes.CALC_TRANSFERENCIA): {
          if (callEvento.data == 'C') {
            this.transferenciaPago.monto = null;
          } else {
            if (this.transferenciaPago.monto == null) {
              this.transferenciaPago.monto = callEvento.data;
            }
            else {
              this.transferenciaPago.monto += callEvento.data;
            }
          }
          break;
        }
        case (TableTypes.CALC_PROPINA): {
          if (callEvento.data == 'C') {
            this.propinaPago.monto = null;
          } else {
            if (this.propinaPago.monto == null) {
              this.propinaPago.monto = callEvento.data;
            }
            else {
              this.propinaPago.monto += callEvento.data;
            }
          }
          break;
        }
        default: {

        }
      }
    });

    //reusar el dialog de producto compuesto para descuento e incidencia y prepago con boleta de venta en alert por mientras
    this.keyboardCharSb = this.keyboardService.currentKeyPressed.subscribe((callEvento: ITableEvento) => {
      switch (callEvento.key) {
        case (TableTypes.CHAR_EFECTIVO): {
          if(callEvento.data=='delete_btn' && this.efectivoPago.comentario!=null){           
            this.efectivoPago.comentario =  this.efectivoPago.comentario.slice(0,-1);
            return;
          }
          if (this.efectivoPago.comentario == null) {
            this.efectivoPago.monto = callEvento.data;
          }
          else {
            this.efectivoPago.monto += callEvento.data;
          }
          break;
        }
        case (TableTypes.CHAR_TARJETA): {
          if(callEvento.data=='delete_btn' && this.tarjetaPago.comentario!=null){            
            this.tarjetaPago.comentario =  this.tarjetaPago.comentario.slice(0,-1);
            return;
          }
          if (this.tarjetaPago.comentario == null) {
            this.tarjetaPago.comentario = callEvento.data;
          }
          else {
            this.tarjetaPago.comentario += callEvento.data;
          }
          break;
        }
        case (TableTypes.CHAR_YAPE): {
          if(callEvento.data=='delete_btn' && this.yapePago.comentario!=null){            
            this.yapePago.comentario =  this.yapePago.comentario.slice(0,-1);
            return;
          }
          if (this.yapePago.comentario == null) {
            this.yapePago.comentario = callEvento.data;
          }
          else {
            this.yapePago.comentario += callEvento.data;
          }
          break;
        }
        case (TableTypes.CHAR_PLIN): {
          if(callEvento.data=='delete_btn' && this.plinPago.comentario!=null){            
            this.plinPago.comentario =  this.plinPago.comentario.slice(0,-1);
            return;
          }
          if (this.plinPago.comentario == null) {
            this.plinPago.comentario = callEvento.data;
          }
          else {
            this.plinPago.comentario += callEvento.data;
          }
          break;
        }
        case (TableTypes.CHAR_TRANSFERENCIA): {
          if(callEvento.data=='delete_btn' && this.transferenciaPago.comentario!=null){            
            this.transferenciaPago.comentario =  this.transferenciaPago.comentario.slice(0,-1);
            return;
          }
          if (this.transferenciaPago.comentario == null) {
            this.transferenciaPago.comentario = callEvento.data;
          }
          else {
            this.transferenciaPago.comentario += callEvento.data;
          }
          break;
        }
        case (TableTypes.CHAR_PROPINA): {
          if(callEvento.data=='delete_btn' && this.propinaPago.comentario!=null){            
            this.propinaPago.comentario =  this.propinaPago.comentario.slice(0,-1);
            return;
          }
          if (this.propinaPago.comentario == null) {
            this.propinaPago.comentario = callEvento.data;
          }
          else {
            this.propinaPago.comentario += callEvento.data;
          }
          break;
        }
        default: {

        }
      }

    });   
         
  }
  cargarProductosXPedido(idTurno: string, idPedido: string) {
    this.listaProductoSb = this.pedidoBLService.getProductosXPedido(idTurno, idPedido)
      .subscribe(listaProds => {
        if (listaProds) {
          this.totalProductos = 0;
          this.listaProductoPedido = [];
          this.spinnerPageService.hide();
          this.listaProductoPedido = JSON.parse(JSON.stringify(listaProds));
          //quitanto productos prepagados
          this.listaProductoPedido = JSON.parse(JSON.stringify(this.listaProductoPedido.filter(prod => prod.es_pagado == undefined)));
          this.listaProductoPedido.forEach(prod => {
            this.totalProductos += prod.cantidad_producto;
          });
        }
    });
  }
 
  //medio de pago
  cargarConfiguracion(){       
    this.mediosBLService.existFecthMedios();
    this.configSb?.unsubscribe();
    this.configSb = this.mediosBLService.getTasas().subscribe(configAmb=>{
        if(configAmb){          
          this.isLoading = false;
          this.mediosAceptados = configAmb;
          this.idMedios = configAmb.id;
          this.existeConfig=true;
          this.cEfectivo = this.mediosAceptados.lista_medios_pago.filter(medio=>medio.codigo_medio == ListaMediosPago.listaMediosPago[0].codigo_medio && medio.es_vigente )[0];
          this.cTarjeta = this.mediosAceptados.lista_medios_pago.filter(medio=>medio.codigo_medio == ListaMediosPago.listaMediosPago[1].codigo_medio && medio.es_vigente)[0];
          this.cTransferencia = this.mediosAceptados.lista_medios_pago.filter(medio=>medio.codigo_medio == ListaMediosPago.listaMediosPago[2].codigo_medio && medio.es_vigente )[0];
          this.cYape = this.mediosAceptados.lista_medios_pago.filter(medio=>medio.codigo_medio == ListaMediosPago.listaMediosPago[3].codigo_medio && medio.es_vigente )[0];
          this.cPlin = this.mediosAceptados.lista_medios_pago.filter(medio=>medio.codigo_medio == ListaMediosPago.listaMediosPago[4].codigo_medio && medio.es_vigente)[0];
          //impuestos igv y tasa de servicio
          this.cargarTasas();
        }else{
          this.isLoading = false;
        }       
      });
  }

  cargarTasas(){
    this.gestionAspectosBL.existFetchTasas();
    this.configTasasSb?.unsubscribe();
    this.aspectosGenerales$ = this.gestionAspectosBL.getTasas().pipe(
      startWith(new AspectosGenerales()), // Valor inicial si es necesario
      filter((config): config is AspectosGenerales => config !== undefined),
      tap((aspGen: AspectosGenerales) => {
        if (aspGen.tasa_impuesto!=0) {
          this.idAspectos = aspGen.id;
          this.existeConfigTasaServicio = true;
          this.tasaImpuesto = aspGen.tasa_impuesto;
          this.tasaServicio = aspGen.tasa_servicio;
          this.incluirServicio = aspGen.incluir_servicio;
          this.siglaImpuesto = aspGen.sigla_impuesto;
          let prodPrecioTotalCentimos =0
          let totalSubProd=0;
          if(this.tasaServicio && this.tipo_pago==TipoPagoType.PARCIAL){
            //convetimos todo a centimos
            this.listaProductoPedido.forEach(prod=>{
                if(prod.es_seleccionado){
                  totalSubProd+=prod.precio_total;
                }
              }
            )
            prodPrecioTotalCentimos = totalSubProd * 100;
            const tasaImpuestoCentimos =  this.tasaImpuesto * 100;
            const totalIGVCentimos = ((prodPrecioTotalCentimos* tasaImpuestoCentimos)/10000);
            const subTotalCentimos = prodPrecioTotalCentimos - totalIGVCentimos;
            this.igvInformativo = totalIGVCentimos/100;
            this.subTotalInformativo = subTotalCentimos/100;
            const tasaServicioCentimos = this.tasaServicio *100
            const totalServicioCentimos = (prodPrecioTotalCentimos * tasaServicioCentimos)/10000
            this.servicioInformativo = totalServicioCentimos/100; //convertido a soles;            
            const preRedondeo = (prodPrecioTotalCentimos+ totalServicioCentimos)/100            
            this.totalInformativo = Utils.redondeoSUNAT(preRedondeo);

          }else if(this.tipo_pago==TipoPagoType.PARCIAL){
            const preRedondeo = prodPrecioTotalCentimos/100
            
            this.totalInformativo = Utils.redondeoSUNAT(preRedondeo);
          }else{
          this.spinnerPageService.hide();
          }         
          this.cdRef.detectChanges();
        }
      }),
      catchError((error: any) => {
        this.uiService.ShowSnackBar(SnackBarType.ERROR, error, 3000);
        return of(new AspectosGenerales()); // Proporciona un valor de retorno seguro
      })
    )
  }


  //sirve para pago total, parcial y dscto
  agregarMonto(codigo: string) {
    const reg: RegExp = /^(\d*)\.?\d*$/
    
    switch (codigo) {
      case ListaMediosPago.listaMediosPago[0].codigo_medio: {
        //verificamos si existe un pago anterior en efectivo, solo se permite uno
        const existePagoAnteriorEfectivo = this.lista_pago_cuenta.filter(pago=>pago.tipo_pago==ListaMediosPago.listaMediosPago[0]);
        if(existePagoAnteriorEfectivo && existePagoAnteriorEfectivo.length>0){
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_MONTO_ERROR_MAS_EFECTIVO, 5000);
          return;
        }
        if (this.efectivoPago.monto != null) {
          if (!reg.test(this.efectivoPago.monto!.toString())) {
            this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_MONTO_ERROR, 5000);
            return;
          }

        } else {
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_MONTO_ERROR, 5000);
          return;
        }
        this.efectivoPago.tipo_pago = ListaMediosPago.listaMediosPago[0];
        this.lista_pago_cuenta.push(this.efectivoPago);
        this.efectivoPago = new PagoCuenta();        
        this.calcularPago();
        break;
      }
      case ListaMediosPago.listaMediosPago[1].codigo_medio: {
        if (this.tarjetaPago.monto != null) {
          if (!reg.test(this.tarjetaPago.monto!.toString())) {
            this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_MONTO_ERROR, 5000);
            return;
          }

        } else {
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_MONTO_ERROR, 5000);
          return;
        }
        this.tarjetaPago.tipo_pago = ListaMediosPago.listaMediosPago[1];
        this.lista_pago_cuenta.push(this.tarjetaPago);
        this.tarjetaPago = new PagoCuenta();        
        this.calcularPago();
        break;
      }
      case ListaMediosPago.listaMediosPago[2].codigo_medio: {
        if (this.transferenciaPago.monto != null) {
          if (!reg.test(this.transferenciaPago.monto!.toString())) {
            this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_MONTO_ERROR, 5000);
            return;
          }

        } else {
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_MONTO_ERROR, 5000);
          return;
        }

        this.transferenciaPago.tipo_pago = ListaMediosPago.listaMediosPago[2];
        this.lista_pago_cuenta.push(this.transferenciaPago);
        this.transferenciaPago = new PagoCuenta();        
        this.calcularPago();
        break;
      }
      case ListaMediosPago.listaMediosPago[3].codigo_medio: {
        if (this.yapePago.monto != null) {
          if (!reg.test(this.yapePago.monto!.toString())) {
            this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_MONTO_ERROR, 5000);
            return;
          }

        } else {
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_MONTO_ERROR, 5000);
          return;
        }
        this.yapePago.tipo_pago = ListaMediosPago.listaMediosPago[3];
        this.lista_pago_cuenta.push(this.yapePago);
        this.yapePago = new PagoCuenta();        
        this.calcularPago();
        break;
      }
      case ListaMediosPago.listaMediosPago[4].codigo_medio: {
        if (this.plinPago.monto != null) {
          if (!reg.test(this.plinPago.monto!.toString())) {
            this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_MONTO_ERROR, 5000);
            return;
          }

        } else {
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_MONTO_ERROR, 5000);
          return;
        }
        this.plinPago.tipo_pago = ListaMediosPago.listaMediosPago[4];
        this.lista_pago_cuenta.push(this.plinPago);
        this.plinPago = new PagoCuenta();        
        this.calcularPago();
        break;
      }
      case ListaMediosPago.listaMediosPago[5].codigo_medio: {
        if (this.propinaPago.monto != null) {
          if (!reg.test(this.propinaPago.monto!.toString())) {
            this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_MONTO_ERROR, 5000);
            return;
          }

        } else {
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_MONTO_ERROR, 5000);
          return;
        }
        this.propinaPago.tipo_pago = ListaMediosPago.listaMediosPago[5];
        this.lista_pago_cuenta.push(this.propinaPago);
        this.propinaPago = new PagoCuenta();        
        this.calcularPago();
        break;
      }
      default: {

      }
    }
  }

  limpiar(){
    this.lista_pago_cuenta= [];
    this.calcularPago();
    this.dialogRef.close();
  }

  cancelar(){
    this.dialogRef.close();
  }

  pagarCuentaParcial(){
    //validar si solo tiene un producto
    if (this.turno.id && this.pedido.id) {
      //validando que haya mas de un producto para hacer el prepago
      const productosDisp = this.listaProductoPedido.filter(prod=>prod.es_seleccionado=true);

      if(productosDisp==undefined || productosDisp.length==1){
        this.uiService.ShowSnackBar(SnackBarType.INFO, Msjs_Validations.MSJ_MINIMO_PRODUCTO_PREPAGO, 5000);
        return;
      }               
    }

    if(this.subTotal<this.totalInformativo!){
      this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs_Validations.MSJ_ERROR_MONTO_TOTAL, 5000);
      return;
    }
    
    this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon: Icons.QUESTION_MARK })
    .subscribe(async result => {
      if(result){
        this.spinnerPageService.show();
        await this.cargarFechas();
        
         //dando la forma a la lista de pagos         
         this.lista_pago_cuenta.forEach(pago=>{
          const pagoCuenta: IPagoCuenta={
            monto: pago.monto,            
            tipo_pago:pago.tipo_pago,
            ...(pago.comentario !== undefined && { comentario: pago.comentario }),
            //el excedente de pago del producto se guarda para mostrar en el reporte y mostrar el cambio devuelto
            ...(this.tieneExcedente && pago.tipo_pago.codigo_medio=== ListaMediosPago.listaMediosPago[0].codigo_medio &&{exedente:Number(this.restantePago.toFixed(3))}),
            usuario_creacion: this.registroService.usuario.correo_electronico,
            fecha_key : {
              anio:this.fechaHoy.anio,
              mes:this.fechaHoy.mes,
              dia:this.fechaHoy.dia,
              hora:this.fechaHoy.hora,
              minuto:this.fechaHoy.minuto,
              segundo:this.fechaHoy.segundo
            },
            usuario_creacion_busqueda: this.registroService.usuario.correo_electronico.split('@')[0],            
          }
          this.lista_pago_cuenta_send.push(pagoCuenta);              
        });

        //creando objeto prepago
        const prePagoCuenta:IPrePagoCuenta={
          lista_producto: this.listaProductoPedido,
          lista_pago_producto: this.lista_pago_cuenta_send,
          igv:this.igvInformativo!,
          ...(this.tasaServicio && this.tipo_pago==TipoPagoType.PARCIAL &&{ servicio:this.servicioInformativo!}),          
        }

        let pedidoTmp = JSON.parse(JSON.stringify(this.pedido));
        let otrosProductos = (this.datoPasado.productos as IProductoTicket[])
        const productosOtros = otrosProductos.filter(prod=>prod.es_seleccionado==undefined);         
        
        if (this.existeConfigTasaServicio) {

          //recalculando el total
          pedidoTmp.total =0;
          productosOtros.forEach(prod=>{
            pedidoTmp.total += prod.precio_total;

          });                  
          const montos = Utils.calcularTotalTicket(this.existeConfigTasaServicio,this.tasaImpuesto, pedidoTmp.total, this.tasaServicio);
          //recalculando totales
          pedidoTmp.igv = montos[0];
          pedidoTmp.sub_total = montos[1];
          if (this.incluirServicio){
            pedidoTmp.servicio_total = montos[2];
            pedidoTmp.servicio_subtotal=montos[3];
            pedidoTmp.servicio_igv=montos[4];
          }          
          pedidoTmp.total = montos[5];      
          this.pedido =  JSON.parse(JSON.stringify(pedidoTmp));
        }
        else{
          this.spinnerPageService.hide();
          this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs_Validations.MSJ_IMPUESTO_NO_EXISTE, 3000);
          return;
        }

        this.pedidoBLService.updatePagarPedidoParcial(pedidoTmp, this.turno.id! , prePagoCuenta).then(tx=>{
          if(tx.tx){
            this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 5000);
            this.spinnerPageService.hide();
            this.dialogRef.close({pedido:this.pedido});
          }
          else{
            this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
            this.spinnerPageService.hide();
          }
        });
      }
    });
  }

  pagarCuenta(){
    this.modalSb = this.uiService.showModalQuestions({ title: Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION, icon: Icons.QUESTION_MARK })
    .subscribe(async result => {
      if(result){
        this.spinnerPageService.show();
        await this.cargarFechas();
        
        //dando la forma a la lista de pagos
         this.lista_pago_cuenta.forEach(pago=>{
           const pagoCuenta: IPagoCuenta={
            monto: pago.monto,            
            tipo_pago:pago.tipo_pago,
            ...(pago.comentario !== undefined && { comentario: pago.comentario }),

            //el excedente de toda la cuenta se guarda para mostrar en el reporte y mostrar el cambio devuelto
            ...(this.tieneExcedente && pago.tipo_pago.codigo_medio=== ListaMediosPago.listaMediosPago[0].codigo_medio &&{exedente:Number(this.restantePago.toFixed(3))}),
            usuario_creacion: this.registroService.usuario.correo_electronico,
            fecha_key : {
              anio:this.fechaHoy.anio,
              mes:this.fechaHoy.mes,
              dia:this.fechaHoy.dia,
              hora:this.fechaHoy.hora,
              minuto:this.fechaHoy.minuto,
              segundo:this.fechaHoy.segundo
            },
            usuario_creacion_busqueda: this.registroService.usuario.correo_electronico.split('@')[0]
          }
          this.lista_pago_cuenta_send.push(pagoCuenta);          
        });
        //tambien inserta ventas cerradas
        this.pedidoBLService.updatePagarPedido(this.pedido, this.turno.id! , this.lista_pago_cuenta_send, this.fechaHoy).then(tx=>{
          if(tx.tx){            
            this.spinnerPageService.hide();
            //aqui llamar a modal de comprobante electronico            
            this.uiService.showDialogEmitirComprobante({ dato:this.pedido, listaProductoPedido:this.listaProductoPedido, idTurno:this.turno.id!});//idVenta envia
            this.uiService.ShowSnackBar(SnackBarType.SUCCESS, Msjs.MSJ_GUARDADO_OK, 3000);
            this.dialogRef.close();
            
          }
          else{            
            this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
            this.spinnerPageService.hide();
          }
        });
      }
    });
  }

  async cargarFechas() {
    const fechaServer = moment(await this.functionService.GetDateServer()).local().format();
    const fechaServerProcesado = Utils.separarFechasHoras(fechaServer);
    this.fechaHoy.anio = fechaServerProcesado[0];//anio
    this.fechaHoy.mes = fechaServerProcesado[1];//mes
    this.fechaHoy.dia = fechaServerProcesado[2];//dia
    this.fechaHoy.hora = fechaServerProcesado[3];//hora
    this.fechaHoy.minuto = fechaServerProcesado[4];//minuto
    this.fechaHoy.segundo = fechaServerProcesado[5];//seg
  }

  calcularPago() {
    this.tieneExcedente = false;
    this.tieneEfectivo = false;
    this.subTotal = 0;
    this.restantePago = 0;
    const codigoMedioPropina = ListaMediosPago.listaMediosPago[5].codigo_medio; // Código de medio de pago para propina
    const tienePropina = this.lista_pago_cuenta.some(pago => pago.tipo_pago.codigo_medio === codigoMedioPropina); // Uso de 'some' es más adecuado aquí
    const tienePagoEfectivo = this.lista_pago_cuenta.some(pago => pago.tipo_pago.codigo_medio === ListaMediosPago.listaMediosPago[0].codigo_medio); // Uso de 'some' es más adecuado aquí
    //viendo si tiene pago en efectivo para permitir pago y mostrar el cambio a devolver
    if (tienePagoEfectivo) {
      this.tieneEfectivo = true;
    }
    // Cálculo del subtotal y restante de pago
    this.lista_pago_cuenta.forEach(pago => {
      if (pago.tipo_pago.codigo_medio !== codigoMedioPropina) {
        this.subTotal += Number(pago.monto);
      }
    });

    // Establecer restantePago dependiendo de si es pago total o parcial
    this.restantePago = Number((this.tipo_pago == TipoPagoType.TOTAL ? this.pedido.total : this.totalInformativo!) - this.subTotal);
    this.lista_pago_cuenta.forEach(pago => {

      // Verificar si hay excedente y si no hay propina
      if (this.tipo_pago == TipoPagoType.TOTAL) {
        if (this.subTotal > this.pedido.total && !tienePropina) {
          this.tienePropina = true;
          this.tieneExcedente = true;
          this.uiService.ShowSnackBar(SnackBarType.INFO, Msjs.MSJ_MONTO_EXEDIDO, 5000);
        }

      }

      if (this.tipo_pago == TipoPagoType.PARCIAL) {
        if (this.subTotal > this.totalInformativo! && !tienePropina) {
          this.tienePropina = true;
          this.tieneExcedente = true;
          this.uiService.ShowSnackBar(SnackBarType.INFO, Msjs.MSJ_MONTO_EXEDIDO, 5000);
        }
      }

    })
  }


  removerPago(pago: PagoCuenta, index:number){
    this.lista_pago_cuenta.splice(index, 1);
    this.calcularPago();
  }

  touchStart(event: TouchEvent) {
    this.startY = event.touches[0].clientY;
  }

  touchMove(event: TouchEvent) {
    this.endY = event.touches[0].clientY;
  }

  touchEnd(event: TouchEvent) {
    if (this.startY > this.endY + 30) {
      //console.log('Deslizado hacia arriba');
      // Acción específica al deslizar hacia arriba
    } else if (this.startY < this.endY - 30) {
      //console.log('Deslizado hacia abajo');
      // Acción específica al deslizar hacia abajo
    }
  }

  onClose(){
    this.dialogRef.close({pedido:this.pedido});
  }

  completarDecimal(event:any){
    let valor = parseFloat(event.target.value);
    if (!isNaN(valor)) {
      event.target.value = valor.toFixed(2);
    }
  }

  seleccionarTodo(event:any){
    event.target.select();
  }


}

import { Component, OnInit } from '@angular/core';
import { Icons } from '../../cons/icons';
import { ListaMediosPago, Msjs, Msjs_Validations, PatternsRegex, SistOperation } from '../../cons/common';
import { NgForm } from '@angular/forms';
import { UIService } from '../../services/ui.services';
import { GestionMontoBlService } from '../gestion-monto/services/gestion-monto.bl.service';
import { Subscription } from 'rxjs';
import { Turno } from '../gestion-monto/models/Turno';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { RegistroService } from '../../services/registro.service';
import { Historial } from '../historial/models/Historial';
import { SolicitarCambioType } from '../../types/solicitar-cambio-type';
import { blinkAnimation } from '../../animations/animations';
import { ProcesoType } from '../../types/proceso-type';
import { VariacionEfectivo } from '../gestion-monto/models/VariacionEfectivo';
import { SpinnerPageService } from '../spinner-page/spinner-page.service';

import * as printJS from 'print-js';
import { Utils } from '../../helpers/utils';
import { VentaCerradaBlService } from '../procesos/services/venta-cerrada-bl.service';
import { filter } from 'rxjs/operators';
import { Ventas } from '../registrar-pedido/models/Ventas';


@Component({
  selector: 'app-gestion-turno',
  templateUrl: './gestion-turno.component.html',
  styleUrls: ['./gestion-turno.component.scss'],
  animations: [blinkAnimation]
})

@AutoUnsubscribe

export class GestionTurnoComponent implements OnInit {
  readonly Icons: typeof Icons = Icons;
  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  readonly SistOperation: typeof SistOperation = SistOperation;
  readonly ListaMediosPago: typeof ListaMediosPago = ListaMediosPago;
  gestionMontoSb!: Subscription;
  turno = new Turno();
  modalSb = new Subscription();
  tieneSolicitudPendiente:boolean = false;
  esVigenteSolicitud:boolean = false;
  rolAdministrador:boolean = false;
  blinkState: string = 'inactive';
  estadoMontoInicial:string='';
  readonly SolicitarCambioType: typeof SolicitarCambioType = SolicitarCambioType;
  readonly ProcesoType: typeof ProcesoType = ProcesoType;
  tipoProcesoPendiente:string='';
  totalEfectivoAnadido:number=0;
  totalEfectivoRetirado:number=0;
  isLoading:boolean = false;
  totalVentasCerradasEfectivo:number = 0;

  //variables para impresion
  empresa!:any;
  logoEmpresa!:string;
  usuario:any;
  ventasSb!: Subscription;
  


  constructor(
    private uiService:UIService,
    private gestionMontoBlService: GestionMontoBlService,
    private registroService: RegistroService,
    private readonly spinnerPageService: SpinnerPageService,
    private ventaCerrada: VentaCerradaBlService,
  ) {

  }

  ngOnInit(): void {
    setInterval(() => {
      this.blinkState = this.blinkState === 'active' ? 'inactive' : 'active';
    }, 1000);

    if(this.registroService.empresa){
      this.isLoading = true;
      if(this.registroService.esAdministrador){
        this.rolAdministrador = true;
      }
      this.cargarTurno();

      //solo para impresion
      this.empresa = this.registroService.empresa;
      if(this.empresa.logo_empresa){
        this.logoEmpresa = Utils.ObjecToListToArray(this.empresa?.logo_empresa)[0].path_image;
      }
      
      this.usuario = this.registroService.usuario;
      
      //
     

    }
  }

  cargarTurno(){
    this.gestionMontoBlService.existFetchTurno();
    this.gestionMontoSb?.unsubscribe();
    this.gestionMontoSb = this.gestionMontoBlService.getTurno().pipe(filter(turno=>turno!=undefined)).subscribe(turn=>{
      this.tieneSolicitudPendiente = false;
      this.esVigenteSolicitud = false;
      this.isLoading = false;
      if(turn){
        this.turno = turn;

        if(turn.correccion_monto_inicial && turn.correccion_monto_inicial.length>0){
          const solicitud= this.turno.correccion_monto_inicial?.filter(item=>
            item.estado_cambio===SolicitarCambioType.SOLICITADO &&
            item.proceso===ProcesoType.CAMBIAR_MONTO_INICIAL &&
            item.es_vigente
            );
          //mostramos el boton siempre en cuando se tenga el rol de administrador
          if( (solicitud && solicitud?.length>0)){
            this.estadoMontoInicial = solicitud[0].estado_cambio;
            this.tieneSolicitudPendiente = true;
            this.esVigenteSolicitud= solicitud[0].es_vigente;
            this.tipoProcesoPendiente = ProcesoType.CAMBIAR_MONTO_INICIAL
          }
        }

        if(turn.montos_aniadidos && turn.montos_aniadidos.length>0){
          this.totalEfectivoAnadido=0;
          const solicitud= this.turno.montos_aniadidos?.filter(item=>
            item.estado_cambio===SolicitarCambioType.SOLICITADO &&
            item.proceso===ProcesoType.CAMBIAR_ANADIR_MONTO &&
            item.es_vigente
            );
          //mostramos el boton siempre en cuando se tenga el rol de administrador
          if( (solicitud && solicitud?.length>0)){
            this.estadoMontoInicial = solicitud[0].estado_cambio;
            this.tieneSolicitudPendiente = true;
            this.esVigenteSolicitud= solicitud[0].es_vigente;
            this.tipoProcesoPendiente = ProcesoType.CAMBIAR_ANADIR_MONTO
          }
          //calculando total
          const totalesAnadidos= this.turno.montos_aniadidos?.filter(item=>
            item.estado_cambio===SolicitarCambioType.APROBADO &&
            item.proceso===ProcesoType.CAMBIAR_ANADIR_MONTO &&
            item.es_vigente
            );
            if(totalesAnadidos && totalesAnadidos.length>0){
              totalesAnadidos?.forEach(total=>{
                this.totalEfectivoAnadido = Number(total.monto)+ this.totalEfectivoAnadido;
              });
            }
        }

        if (turn.montos_retirados && turn.montos_retirados.length > 0) {
          this.totalEfectivoRetirado=0;
          const solicitud = this.turno.montos_retirados?.filter(item =>
            item.estado_cambio === SolicitarCambioType.SOLICITADO &&
            item.proceso === ProcesoType.CAMBIAR_RETIRAR_MONTO &&
            item.es_vigente
          );
          //mostramos el boton siempre en cuando se tenga el rol de administrador
          if ((solicitud && solicitud?.length > 0)) {
            this.estadoMontoInicial = solicitud[0].estado_cambio;
            this.tieneSolicitudPendiente = true;
            this.esVigenteSolicitud = solicitud[0].es_vigente;
            this.tipoProcesoPendiente = ProcesoType.CAMBIAR_RETIRAR_MONTO
          }
          //calculando total
          const totalesRetirados = this.turno.montos_retirados?.filter(item =>
            item.estado_cambio === SolicitarCambioType.APROBADO &&
            item.proceso === ProcesoType.CAMBIAR_RETIRAR_MONTO &&
            item.es_vigente
          );
          if (totalesRetirados && totalesRetirados.length > 0) {
            totalesRetirados?.forEach(total => {
              this.totalEfectivoRetirado = Number(total.monto) + this.totalEfectivoRetirado;
            });
          }
        }     
        }else{
          this.spinnerPageService.hide();
        }
        //cargando ventas cerradas para saber cuanto se puede retirar
        this.ventaCerrada.existFechVenta(this.turno.id!);
        this.ventasSb?.unsubscribe();
        this.ventasSb = this.ventaCerrada.getVenta().pipe(filter((ventas) => ventas !== undefined)).subscribe((ventas) => {          
          if (ventas) {
            this.calcularIndicadoresEconomicos(ventas);            
          }
        });
      }
    );
  }

  calcularIndicadoresEconomicos(listaVentas: Ventas[]) {
    this.totalVentasCerradasEfectivo =0;
    listaVentas.forEach((ventaX) => {
      ventaX.pedido!.lista_pago_total!.forEach((pago) => {
        //solo efectivo se considera para caja, las otras cajas de monedero, tarjetas 
        if(pago.tipo_pago.codigo_medio===ListaMediosPago.listaMediosPago[0].codigo_medio){
          if(pago.exedente!=undefined){            
            this.totalVentasCerradasEfectivo += Number(pago.monto!) - (Number(pago.exedente)*-1);
          }else{            
            this.totalVentasCerradasEfectivo += Number(pago.monto!);
          }
        }      
      });
    });
  }

  mostrarHistorialAnadir(){
    let listaHistorial:Historial[]=[];

    if(this.turno.montos_aniadidos){
      this.turno.montos_aniadidos.forEach((correccion, index)=>{
        const historial:Historial ={
          indice: index+1,
          fecha:correccion.fecha_key,
          actual:correccion.monto.toString(),
          usuario_creacion:correccion.usuario_creacion,
          estado: correccion.estado_cambio,
          proceso: correccion.proceso,
          usuario_aprobacion:correccion.usuario_aprobacion
        }
        listaHistorial.push(historial);

      });

    }

    this.uiService.showModalHistorial({operacion:SistOperation.NUEVO, data:listaHistorial});

  }

  mostrarHistorialRetirar(){
    let listaHistorial:Historial[]=[];

    if(this.turno.montos_retirados){
      this.turno.montos_retirados.forEach((correccion, index)=>{
        const historial:Historial ={
          indice: index+1,
          fecha:correccion.fecha_key,
          actual:correccion.monto.toString(),
          usuario_creacion:correccion.usuario_creacion,
          estado: correccion.estado_cambio,
          proceso: correccion.proceso,
          anotacion: correccion.anotacion,
          usuario_aprobacion:correccion.usuario_aprobacion
        }
        listaHistorial.push(historial);

      });

    }

    this.uiService.showModalHistorial({operacion:SistOperation.NUEVO, data:listaHistorial});
  }

  nuevoTurno(){
    this.uiService.showModalGestionMonto({operacion:SistOperation.NUEVO, data:null});
  }

  cerrarTurno(){}

  modificarEfectivoInicial(){
    // puede ser colaborador o aministrador
    if(this.registroService.esAdministrador){
      this.uiService.showModalGestionMonto({operacion:SistOperation.REINGRESAR, data:this.turno});
    }
    else{
      this.modalSb= this.uiService.showModalQuestions({title:Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION_SOLICITUD, icon:Icons.QUESTION_MARK})
      .subscribe(result=>{
        if(result){
          this.uiService.showModalGestionMonto({operacion:SistOperation.SOLICITAR, data:this.turno});
        }
      });

    }

  }

  retirarEfectivo(){    
    if(this.rolAdministrador)
    {
      this.uiService.showModalGestionMonto({operacion:SistOperation.RETIRAR, data:this.turno, venta_actual:this.totalVentasCerradasEfectivo});
    }
    else{
      this.modalSb= this.uiService.showModalQuestions({title:Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION_SOLICITUD, icon:Icons.QUESTION_MARK})
      .subscribe(result=>{
        if(result){
          this.uiService.showModalGestionMonto({operacion:SistOperation.RETIRAR, data:this.turno, venta_actual:this.totalVentasCerradasEfectivo});
        }
      });
    }


  }

  aniadirEfectivo(){
    if(this.rolAdministrador)
    {
      this.uiService.showModalGestionMonto({operacion:SistOperation.AGREGAR, data:this.turno});
    }
    else{
      this.modalSb= this.uiService.showModalQuestions({title:Msjs.TITULO_CONFIRMACION, message: Msjs.MENSAJE_CONFIRMACION_SOLICITUD, icon:Icons.QUESTION_MARK})
      .subscribe(result=>{
        if(result){
          this.uiService.showModalGestionMonto({operacion:SistOperation.AGREGAR, data:this.turno});
        }
      });
    }
  }

  mostrarHistorial(){
    let listaHistorial:Historial[]=[];

    if(this.turno.correccion_monto_inicial){
      this.turno.correccion_monto_inicial.forEach((correccion, index)=>{
        const historial:Historial ={
          indice: index+1,
          fecha:correccion.fecha_key,
          anterior:correccion.monto_anterior.toString(),
          actual:correccion.monto_actual.toString(),
          usuario_creacion:correccion.usuario_creacion,
          estado: correccion.estado_cambio,
          proceso: correccion.proceso,
          usuario_aprobacion: correccion.usuario_aprobacion
        }
        listaHistorial.push(historial);

      });

    } 

    this.uiService.showModalHistorial({operacion:SistOperation.NUEVO, data:listaHistorial});
  }

  procesarSolicitud(operacion: SistOperation){
    switch(operacion){
      case SistOperation.PROCESAR_SOLICITUD:{
        this.uiService.showModalGestionMonto({operacion:operacion, data:this.turno});
        break;
      }
      case SistOperation.PROCESAR_AGREGAR:{
        this.uiService.showModalGestionMonto({operacion:operacion, data:this.turno});
        break;
      }
      case SistOperation.PROCESAR_RETIRAR:{
        this.uiService.showModalGestionMonto({operacion:operacion, data:this.turno});
        break;
      }
    }

  }

  imprimir(item: VariacionEfectivo){
    var style =`
    .ticket {
      font-family: 'IBMPlexSans', sans-serif;
      font-size: 10pt;
    }
    .logo{
      text-align: center;
      margin-top: 10px;
    }
    .subtitle{
      font-size: 10pt;
      font-family: 'IBMPlexSans', sans-serif;
    }
    .centrado {
      text-align: center;
      align-content: center;
      font-family: 'IBMPlexSans', sans-serif;
    }
    .usuarios {
      text-align: left;
    }
    .monto{
      font-size: 10pt;
      margin-top: 20px;
      margin-bottom: 50px;
      font-family: 'IBMPlexSans', sans-serif;
    }
    .titulo-ticket{
      margin-top: 5px;
      margin-bottom: 5px;
      font-family: 'IBMPlexSans', sans-serif;
    }
    .contenedor-firma{
      margin-top: 150px;
    }
    .firma{
      border-bottom: solid 2px;
    }
    .texto-firma{
      margin-bottom: 50px;
    }
    .table{
      width: 100%;
      border-collapse: collapse;

    }
    .col-left{
      width: 10%;
    }
    .col-center{
      width: 80%;
      height: 120px;
    }
    .col-right{
      width: 10%;
    }
    .comentario{
      text-align: left;
      border-bottom: solid 2px;
      margin-bottom: 30px;

    }
    .footer-imprimir{
      text-align: left;
    }
    .texto{
      font-family: 'IBMPlexSans', sans-serif;
    }

    `


      let dateTime ='Impresión: '+ item.fecha_key.anio+'/'+
        item.fecha_key.mes+'/'+
        item.fecha_key.dia+' '+
        item.fecha_key.hora+':'+
        item.fecha_key.minuto+':'+
        item.fecha_key.segundo

      const id = item.fecha_key.anio+
        item.fecha_key.mes+
        item.fecha_key.dia+
        item.fecha_key.hora+
        item.fecha_key.minuto+
        item.fecha_key.segundo

    printJS({ printable: id, type: 'html', style:style, css:['https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500&display=swap'] });

  }

}
